/**
 * @file social-feed-carousel.js
 * @description Social feed carousel
 */

import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'

/**
 * Social feed carousel configuration
 * @type {Object}
 */
let socialFeedCarouselConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'social-feed-carousel',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperElement: '.social-feed-carousel',
    swiperConfig: {
        wrapperClass: 'social-feed-carousel-wrapper',
        slideClass: 'social-feed-item',
        slidesPerView: 'auto',
        preloadImages: false,
        lazy: {
            loadingClass: 'loading',
            loadedClass: 'loaded',
            preloaderClass: 'loader'
        },
        watchSlidesVisibility: true
    },
    swiperPaginationConfig: {
        draggable: true,
        snapOnRelease: false
    },

    /**
     * Component mounted
     * @return  {Void}
     */
    mounted() {
        if (this.carouselInstance) {
            // Perform slides update
            // this.slidesUpdate();

            // Handle carousel events
            this.carouselInstance
                .on(
                    'lazyImageReady',
                    slide => {
                        slide.querySelector('.placeholder').classList.add('hidden')
                        this.carouselInstance.update()
                    }
                )
            // .on('slideChange', this.slidesUpdate)
            // .on('reachEnd', this.clearNextClassnames);
        }
    },

    /**
     * Component methods
     */
    methods: {
        /**
         * Slides update
         * Toggle next classname on each slide according to active index
         * @return  {Void}
         */
        slidesUpdate() {
            _.each(
                this.carouselInstance.slides,
                (slide, index) => {
                    if (index > this.carouselInstance.activeIndex) {
                        slide.classList.add('next')
                    } else {
                        slide.classList.remove('next')
                    }
                }
            )
        },

        /**
         * Clear next classnames
         * Remove next classname on each slides
         * @return  {Void}
         */
        clearNextClassnames() {
            _.each(
                this.carouselInstance.slides,
                slide => {
                    slide.classList.remove('next')
                }
            )
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const SocialFeedCarousel = Vue.extend(socialFeedCarouselConfiguration)

/**
 * Module export
 */
export default SocialFeedCarousel

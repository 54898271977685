/**
 * @file theatre-du-chatelet.js
 * @description Theatre du chatelet
 */

import ScrollMagic from 'scrollmagic'
import LoadScript from 'load-script'
import { TimelineMax, TweenMax, Power4 } from 'gsap'
import { EVENTS } from '~/config/constants'
import store from '~/store/main'
import BasicMixin from '~/mixins/basic'
import PageHeader from '~/components/header'
import HeroSlider from '~/components/hero-slider'
import EventHighlight from '~/components/event-highlight'
import EventCarousel from '~/components/event-carousel'
import EventRelatedCarousel from '~/components/event-related-carousel'
import EventDates from '~/components/event-dates'
import EventGalerie from '~/components/event-galerie'
import EventsList from '~/components/events-list'
import EventsFilter from '~/components/events-filter.vue'
import MagazineHeroCarousel from '~/components/magazine-hero-carousel'
import HomeFilter from '~/components/home-filter.vue'
import MediasCarousel from '~/components/medias-carousel'
import ArticleCarousel from '~/components/article-carousel'
import ArtistesFilter from '~/components/artistes-filter.vue'
import ArtistesList from '~/components/artistes-list.vue'
import ArtistesCarousel from '~/components/artistes-carousel'
import SocialFeedCarousel from '~/components/social-feed-carousel'
import UserFormLogin from '~/components/user-form-login'
import UserFormRegister from '~/components/user-form-register'
import VideoTeaser from '~/components/video-teaser'
import VisualGuide from '~/tools/visual-guide/visual-guide.vue'
import ChateletBgPattern from '~/components/chatelet-bg-pattern/chatelet-bg-pattern.vue'
import Actualites from '~/components/actualites'

// TDC Configurations
let theatreDuChateletConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'theatre-du-chatelet',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Child components
     * @type {Object}
     */
    components: {
        PageHeader,
        HeroSlider,
        EventHighlight,
        EventsFilter,
        EventsList,
        EventCarousel,
        EventRelatedCarousel,
        EventDates,
        EventGalerie,
        ArticleCarousel,
        ArtistesFilter,
        ArtistesCarousel,
        ArtistesList,
        UserFormLogin,
        UserFormRegister,
        VideoTeaser,
        VisualGuide,
        HomeFilter,
        MediasCarousel,
        MagazineHeroCarousel,
        ChateletBgPattern,
        SocialFeedCarousel,
        Actualites
    },

    /**
     * Storage ref
     */
    store,

    /**
     * Component data
     * @return {Object}
     */
    data() {
        const data = { contentWidth: 0 }

        return data
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        visibleAlerts: () => store.state.alerts,
        alertshown: () => store.state.alertshown,
        alertnext: () => store.state.alertnext,
        teaserint: () => store.state.teaserint
    },

    /**
     * Data watcher
     * @type {Object}
     */
    watch: {
        contentWidth(value) {
            if (value) {
                $('.glob').width(value)
            }
        }
    },

    /**
     * Component created
     * @return {Void}
     */
    created() {
        this.newsletterVisible = false
        this.newsletterReady = false
        this.newsletterTimeline = false
        this.newsletterFormReady = true
    },

    /**
     * Component mounted event handler
     * @return {Void}
     */
    mounted() {
        const base = this
        const twitterApi = document.createElement('script')
        const twitterBlocks = document.querySelectorAll('.twitter-tweet')
        const instagramApi = document.createElement('script')
        const instagramBlocks = document.querySelectorAll('.instagram-media')

        twitterApi.src = '//platform.twitter.com/widgets.js'
        twitterApi.async = true
        twitterApi.defer = true

        instagramApi.src = '//www.instagram.com/embed.js'
        instagramApi.async = true
        instagramApi.defer = true

        // Set app ready classname
        document.documentElement.classList.add('app-ready')

        if (twitterBlocks && twitterBlocks.length) {
            twitterApi.addEventListener('load', function() {
                console.log('Actualite: twitter api loaded')

                if (window.twttr) {
                    console.log('Actualite: bind twitter events')

                    window.twttr.events.bind(
                        'loaded',
                        event => {
                            event.widgets.forEach(
                                () => {
                                    console.log('Actualite: load event')
                                    base.$event.$emit(EVENTS.WIDGET_LOADED)
                                }
                            )
                        }
                    )
                }
            })

            document.head.appendChild(twitterApi)
        }

        if (instagramBlocks && instagramBlocks.length) {
            instagramApi.addEventListener(
                'load', () => {
                    console.log('Actualite: instagram api loaded')
                    base.$event.$emit(EVENTS.WIDGET_LOADED)
                }
            )

            document.head.appendChild(instagramApi)
        }

        // Set global content width
        this.setGlobContentWidth()
        this.attachGlobalBehaviors()
        this.initImagesLazyLoading()
        this.scrollToAnchor()
        this.registerTimelines()
        this.updateEventContentImages()

        // Resize handler
        this.$event.$on(EVENTS.WINDOW_RESIZE_IMMEDIATE, this.setGlobContentWidth)
        this.$event.$on(EVENTS.KEY_UP_ESCAPE, this.hideLayers)
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Scroll back to top of the page
         * @return {Void}
         */
        backTop() {
            TweenMax.to(
                document.querySelector('.main-app-wrapper'),
                1.6,
                {
                    scrollTo: {
                        y: 0,
                        x: 0,
                        autoKill: false
                    },
                    ease: Power4.easeInOut
                }
            )
        },

        /**
         * Scroll to element
         * @param  {String} target Target element ID
         * @return {Void}
         */
        scrollToTarget(target, duration = 1, offset = 0) {
            let targetElement = document.getElementById(target)

            if (targetElement && targetElement instanceof HTMLElement) {
                TweenMax.to(
                    document.querySelector('.main-app-wrapper'),
                    duration,
                    {
                        scrollTo: {
                            y: '#' + target,
                            x: 0,
                            offsetY: offset,
                            autoKill: false
                        },
                        ease: Power4.easeInOut
                    }
                )
            }
        },

        /**
         * Setting global cntent width
         */
        setGlobContentWidth() {
            let appWrapper = document.querySelector('.app-wrapper')

            if (appWrapper instanceof HTMLElement) {
                this.contentWidth = appWrapper.clientWidth
            }
        },

        /**
         * Lazy load images
         * @return {Void}
         */
        initImagesLazyLoading() {
            const base = this

            $('.lazyload:not(.lazy-process)').each(
                function() {
                    const parent = $(this).parents('.thumb')
                    const figure = $(this).parents('figure')
                    const immediate = $(this).hasClass('immediate')
                    const gallery = $(this).hasClass('gallery')
                    let start = false
                    let scene = false

                    /**
                     * Element scene
                     * @type {ScrollMagic}
                     */
                    if (!immediate) {
                        scene = new ScrollMagic.Scene(
                            {
                                triggerElement: this,
                                triggerHook: 'onEnter'
                            }
                        )

                        scene
                            // .addIndicators({ name: 'media' })
                            .addTo(base.$event.scrollController)
                    }

                    /**
                     * Lazy processed
                     */
                    $(this).addClass('lazy-process')

                    /**
                     * Set parent classname
                     */
                    if ($(this).hasClass('portrait')) {
                        parent.addClass('portrait')
                    }

                    if ($(this).hasClass('square')) {
                        parent.addClass('square')
                    }

                    if ($(this).hasClass('landscape')) {
                        parent.addClass('landscape')
                    }

                    if ($(this).data('background')) {
                        let image = new Image()
                        let source = $(this).data('background')

                        start = () => {
                            image.addEventListener(
                                'load',
                                () => {
                                    // Destroy scene
                                    if (scene) {
                                        scene.destroy()
                                    }

                                    // Update element attributes
                                    $(this)
                                        .css('background-image', 'url(' + source + ')')
                                        .removeAttr('data-background')
                                        .removeClass('lazyload lazy-process')

                                    // Update parent attributes
                                    parent.addClass('ready')
                                }
                            )

                            TweenMax.set(
                                image,
                                { src: source }
                            )
                        }
                    } else {
                        start = () => {
                            $(this).on(
                                'load',
                                () => {
                                    // Destroy scene
                                    if (scene) {
                                        scene.destroy()
                                    }

                                    // Update element attributes
                                    $(this)
                                        .removeAttr('data-src')
                                        .removeAttr('data-srcset')
                                        .removeClass('lazyload lazy-process')

                                    // Update parent attributes
                                    parent.addClass('ready')

                                    if (gallery && navigator.userAgent.match(/firefox/i)) {
                                        figure.width($(this).width())
                                    }
                                }
                            )

                            if ($(this).data('srcset')) {
                                TweenMax.set(
                                    this,
                                    { attr: { srcset: $(this).data('srcset') } }
                                )
                            }

                            if ($(this).data('src')) {
                                TweenMax.set(
                                    this,
                                    { attr: { src: $(this).data('src') } }
                                )
                            }
                        }
                    }

                    /**
                     * Draw video player
                     */
                    if (parent.hasClass('with-video')) {
                        const video = parent.data('video')
                        const playerContainer = parent.find('.player')
                        const player = document.createElement('iframe')

                        // Add button click handler
                        parent.find('button')
                            .click(
                                () => {
                                    // Video ready
                                    parent.addClass('player-load')

                                    // Build youtube frame
                                    player.setAttribute('src', '//www.youtube.com/embed/' + video + '?feature=oembed&mute=1&autoplay=1&modestbranding=1&showinfo=0&cc_load_policy=0&iv_load_policy=3&autohide=1')
                                    player.setAttribute('width', 720)
                                    player.setAttribute('height', 405)
                                    player.setAttribute('frameborder', '0')
                                    player.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture')
                                    player.setAttribute('allowfullscreen', 'allowfullscreen')
                                    player.setAttribute('allowtransparency', 'allowtransparency')

                                    // Handle frame load
                                    player.addEventListener(
                                        'load',
                                        () => {
                                            // youtubePlayer.playVideo();
                                            parent.addClass('player-ready')
                                        }
                                    )

                                    // Append frame
                                    if (playerContainer) {
                                        playerContainer.append(player)
                                    }
                                }
                            )
                    }

                    /**
                     * Handling scene start
                     */
                    if (immediate) {
                        if (start && start instanceof Function) {
                            start()
                        }
                    } else {
                        scene.on(
                            'start',
                            () => {
                                if (start && start instanceof Function) {
                                    start()
                                }
                            }
                        )
                    }
                }
            )
        },

        /**
         * Attach global behaviors
         * @return {Void}
         */
        attachGlobalBehaviors() {
            /**
             * Handle image load
             */
            this.$event.$on(EVENTS.IMAGE_LOAD, this.initImagesLazyLoading)

            /**
             * Expand & collapse aside
             * In event details content
             */
            $('.event-aside .infos-item').each(
                (index, item) => {
                    const $parent = $(item)

                    if(index === 0) {
                        $parent.addClass('active')
                        $parent.find('.infos-item-content').show()
                    }

                    $('h3', $parent).click(
                        function() {
                            if ($parent.is('.active')) {
                                $parent.removeClass('active')

                                $(this).next()
                                    .slideUp(
                                        {
                                            duration: 400,
                                            ease: 'easeInOut'
                                        }
                                    )
                            } else {
                                $('.event-aside .infos-item').not($parent)
                                    .removeClass('active')
                                    .find('.infos-item-content')
                                    .slideUp(
                                        {
                                            duration: 400,
                                            ease: 'easeInOut'
                                        }
                                    )

                                $parent.addClass('active')

                                $(this).next()
                                    .slideDown(
                                        {
                                            duration: 500,
                                            ease: 'easeInOut'
                                        }
                                    )
                            }
                        }
                    )
                }
            )

            $(document).click(
                event => {
                    if (!$(event.target).parents('.has-child').length) {
                        $('.has-child').removeClass('active')
                    }

                    if (!$(event.target).parents('.archives').length) {
                        $('.archives').removeClass('active')
                    }
                }
            )
        },

        /**
         * Toggle tab menu classname
         * @param  {String} name
         * @param  {Event} event
         * @return {Void}
         */
        toggleTabMenuClassName(name, event) {
            event.target.parentNode.classList.toggle(name)
        },

        /**
         * Do action
         * @return {Void}
         */
        doAction(target) {
            if (target) {
                window.location.href = target
            }
        },

        /**
        * ClientHideVideoTeaser
        * @return {Boolean}
        */
        checkvideoteaserstatus(activeAlertCount, lang, ids) {
            if ((!this.alertshown[lang] || this.alertshown[lang] === null) && (activeAlertCount === 0 || (this.visibleAlerts[lang] && this.visibleAlerts[lang].length >= activeAlertCount))) {
                if (this.visibleAlerts[lang]) {
                    const visible = this.visibleAlerts[lang]
                    let difference = ids.filter(x => !visible.includes(x))

                    if (difference.length === 0) {
                        store.commit('addAlertNext', {
                            lang: lang,
                            value: true
                        })
                    }
                }
                if (activeAlertCount === 0) {
                    store.commit('addAlertNext', {
                        lang: lang,
                        value: true
                    })
                }
            }
            if (this.alertnext[lang] && this.alertnext[lang] === true) {
                if (localStorage.hideteaser) {
                    const currentStorageState = JSON.parse(localStorage.hideteaser)

                    if (currentStorageState[lang] && currentStorageState[lang].value === true) {
                        const today = new Date()

                        if (currentStorageState[lang].duration >= today) {
                            return false
                        } else {
                            currentStorageState[lang] = {}
                            localStorage.hideteaser = JSON.stringify(currentStorageState)
                            store.commit('toggleTeaserInt', {
                                lang: lang,
                                value: true
                            })

                            return true
                        }
                    }
                }
                store.commit('toggleTeaserInt', {
                    lang: lang,
                    value: true
                })

                return true
            }

            return false
        },

        /**
         * Scroll to anchror
         * @return {Void}
         */
        scrollToAnchor() {
            const hashTarget = window.location.hash.replace('#', '')
            const allowedAnchor = ['event-date', 'coming-events', 'past-events', 'video']

            if (hashTarget) {
                if (allowedAnchor.indexOf(hashTarget) > -1) {
                    this.scrollToTarget(hashTarget, 0)

                    if (hashTarget === 'video') {
                        $('#' + hashTarget)
                            .next('.thumb')
                            .find('button')
                            .click()
                    }
                } else {
                    this.scrollToTarget(hashTarget, 0, 80)
                }
            }
        },

        /**
         * Register timelines
         * @return {Void}
         */
        registerTimelines() {
            const newsletterOverlay = document.querySelector('.block-newsletter-subscribe')
            const newsletterInner = document.querySelector('.block-newsletter-inner')

            if (newsletterOverlay && newsletterInner && newsletterOverlay instanceof HTMLElement && newsletterInner instanceof HTMLElement) {
                const loader = newsletterInner.querySelector('.loader')
                const lang = newsletterOverlay.dataset.lang

                this.newsletterTimeline = new TimelineMax(
                    {
                        paused: true,
                        yoyo: true,
                        onStartScope: this,
                        onReverseCompleteScope: this,
                        onStart() {
                            this.newsletterVisible = true
                        },
                        onReverseComplete() {
                            let newsletterform = document.getElementById('stx-newsletter')
                            let newsletterWidget = document.querySelector('.stx-Widget')

                            $('.error-message', newsletterOverlay).hide()

                            if (newsletterWidget && newsletterWidget instanceof HTMLElement) {
                                newsletterform.removeChild(newsletterWidget)
                            }

                            this.newsletterFormReady = false
                            this.newsletterVisible = false
                        }
                    }
                )

                this.newsletterTimeline.fromTo(
                    newsletterOverlay,
                    0.5,
                    { autoAlpha: 0 },
                    {
                        autoAlpha: 1,
                        immediateRender: true,
                        ease: Power4.easeOut
                    }
                )

                this.newsletterTimeline.fromTo(
                    newsletterInner,
                    0.5,
                    {
                        autoAlpha: 0,
                        y: 100
                    },
                    {
                        autoAlpha: 1,
                        y: 0,
                        immediateRender: true,
                        ease: Power4.easeOut,
                        onCompleteScope: this,
                        onComplete() {
                            if (!this.newsletterReady) {
                                loader.classList.add('loading')

                                LoadScript(
                                    'https://cha.widgets.secutix.com/stx-widgets/Newsletter/v1/Newsletter.js',
                                    error => {
                                        if (error) {
                                            console.log('Newsletter error', error)
                                            $('.error-message', newsletterOverlay).show()
                                        } else {
                                            this.newsletterReady = true

                                            loader.classList.remove('loading')

                                            STX.config(
                                                {
                                                    apiKey: 'aa4803b6-8677-4b50-bead-b91604e73095',
                                                    hostname: 'billetterie.chatelet.com'
                                                }
                                            )

                                            STX.widgets
                                                .Newsletter
                                                .render(
                                                    {
                                                        props: {},
                                                        locale: lang,
                                                        root: '#stx-newsletter'
                                                    }
                                                )
                                        }
                                    }
                                )
                            }

                            if (!this.newsletterFormReady) {
                                loader.classList.add('loading')

                                STX.config(
                                    {
                                        apiKey: 'aa4803b6-8677-4b50-bead-b91604e73095',
                                        hostname: 'billetterie.chatelet.com'
                                    }
                                )

                                STX.widgets
                                    .Newsletter
                                    .render(
                                        {
                                            props: {},
                                            locale: lang,
                                            root: '#stx-newsletter'
                                        }
                                    ).then(
                                        () => {
                                            loader.classList.remove('loading')
                                        }
                                    )
                            }
                        }
                    },
                    '-=0.1'
                )
            }
        },

        /**
         * Show newsletter overlay
         * @return {Void}
         */
        showNewsletterOverlay() {
            if (this.newsletterTimeline) {
                this.newsletterTimeline.play()
            }
        },

        /**
         * Hide newsletter overlay
         * @return {Void}
         */
        hideNewsletterOverlay() {
            if (this.newsletterTimeline) {
                this.newsletterTimeline.reverse()
            }
        },

        /**
         * Update event content images marker
         * @return {Void}
         */
        updateEventContentImages() {
            $('header.event').each(
                function() {
                    const color = $(this).data('cta-color')

                    $('.editor-content .mark').each(
                        function() {
                            $(this).css({ backgroundColor: color })
                            $(this).addClass('active')
                        }
                    )
                }
            )
        },

        /**
         * Hide layers
         * @return {[type]} [description]
         */
        hideLayers() {
            if (this.newsletterVisible) {
                this.hideNewsletterOverlay()
            }
        }
    }
}

/**
 * Class TheatreDuChatelet
 */
export default class TheatreDuChatelet {
    /**
     * Main app constructor
     * @param  {String} target Target element ID
     * @return {Boolean|Vue}
     */
    constructor(target) {
        let vueInstance = false

        this.target = document.getElementById(target)

        if (this.target && this.target instanceof HTMLElement) {
            vueInstance = this.build()
        }

        return vueInstance
    }

    /**
     * Build vue instance
     * @return {Vue}
     */
    build() {
        theatreDuChateletConfiguration.el = this.target

        return new Vue(theatreDuChateletConfiguration)
    }
}

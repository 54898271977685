/**
 * @file search.js
 * @description Header search
 */

import axios from 'axios'
import { TimelineMax, TweenMax, Power4 } from 'gsap'
import store from '~/store/main'
import { EVENTS } from '~/config/constants'
import BasicMixin from '~/mixins/basic'

let mainSearchConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'main-search',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        active: {
            type: Boolean,
            default: false
        },
        demo: {
            type: Boolean,
            default: false
        },
        action: {
            type: String,
            default: '/'
        },
        qstring: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        ajaxurl: {
            type: String,
            default: ''
        },
        ensavoir: {
            type: String,
            default: 'En savoir plus'
        },
        noresults: {
            type: String,
            default: 'Aucun résultat'
        }
    },

    /**
     * Component data
     * @return {Void}
     */
    data() {
        const data = {
            loading: false,
            query: this.qstring,
            searchRequest: null,
            resultsData: {
                found_posts: 0,
                posts: [],
                result_text: 'résultats',
                result_all_text: 'Voir tous les résultats'
            }
        }

        return data
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        /**
         * Search
         * @return {Boolean}
         */
        search: () => store.state.search
    },

    /**
     * Data watchers
     * @type {Object}
     */
    watch: {
        query(incoming) {
            if (incoming.length > 3) {
                this.loading = true

                if (this.demo) {
                    this.startSearch()
                } else {
                    this.initSearch()
                }
            } else {
                this.clearResult()
            }
        },

        search() {
            if (this.formTween) {
                this.formTween.play()
            }
        }
    },

    /**
     * Component created handler
     * @return {Void}
     */
    created() {
        this.formTween = false
        this.fakeSearch = false
    },

    /**
     * Component mounted handler
     * @return {Void}
     */
    mounted() {
        this.registerTween()
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Register tween
         * @return {Void}
         */
        registerTween() {
            /**
             * Search form tween
             */
            this.formTween = new TimelineMax({ paused: true })

            if (this.$refs.form instanceof HTMLElement) {
                this.formTween
                    .fromTo(
                        this.$refs.form,
                        0.8,
                        { yPercent: -100 },
                        {
                            yPercent: 0,
                            ease: Power4.easeInOut,
                            immediateRender: true,
                            onCompleteScope: this,
                            onStart() {
                                document.body.classList.add('search-visible')
                            },
                            onComplete() {
                                if (this.$refs.field) {
                                    setTimeout(
                                        () => {
                                            this.$refs.field.focus()
                                        },
                                        250
                                    )
                                }
                            }
                        }
                    )
                    .addPause()
                    .to(
                        this.$refs.form,
                        0.5,
                        {
                            yPercent: -100,
                            ease: Power4.easeInOut,
                            onStartScope: this,
                            onCompleteScope: this,
                            onStart() {
                                document.body.classList.remove('search-visible')

                                TweenMax.to(
                                    this.$refs.result,
                                    1,
                                    {
                                        yPercent: -100,
                                        ease: Power4.easeOut
                                    }
                                )
                            },
                            onComplete() {
                                this.formTween.pause(0, true)
                                this.query = ''
                            }
                        }
                    )
            }

            if (this.$refs.result instanceof HTMLElement) {
                TweenMax.set(
                    this.$refs.result,
                    { yPercent: -100 }
                )
            }
        },

        /**
         * Hide search layer
         * @return {Void}
         */
        hide() {
            this.$event.dispatch(EVENTS.SEARCH_HIDE)
        },

        /**
         * Start search
         * @return {Void}
         */
        startSearch() {
            if (this.fakeSearch) {
                clearTimeout(this.fakeSearch)
            }

            this.fakeSearch = setTimeout(
                () => {
                    let resultData = {
                        found_posts: 52,
                        posts: [],
                        result_text: 'résultats',
                        result_all_text: 'Voir tous les résultats'
                    }

                    for (let x = 0; x < 4; x++) {
                        resultData.posts.push(
                            {
                                title: 'Lorem ipsum dolor sit amet',
                                type: 'Concert',
                                url: '#'
                            }
                        )
                    }

                    this.buildResult(resultData)
                },
                2000
            )
        },

        /**
         * Search
         * @return Array
         */
        initSearch() {
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()

            if (this.searchRequest) {
                this.searchRequest.cancel('Search request cancelled')
            }

            this.searchRequest = source
            axios
                .get(
                    this.ajaxurl,
                    {
                        cancelToken: source.token,
                        params: {
                            action: 'get_search_result',
                            qstring: this.query
                        },
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
                    }
                )
                .then(
                    response => {
                        // console.log(response.data);
                        this.buildResult(response.data)
                    }
                )
                .catch(
                    error => {
                        console.log(error.message)
                    }
                )
        },

        /**
         * Clear result
         * @return {Void}
         */
        clearResult() {
            this.loading = false

            if (this.fakeSearch) {
                clearTimeout(this.fakeSearch)
            }

            if (this.$refs.result instanceof HTMLElement) {
                TweenMax.to(
                    this.$refs.result,
                    1,
                    {
                        yPercent: -100,
                        ease: Power4.easeOut
                    }
                )
            }
        },

        buildResult(data) {
            this.resultsData.found_posts = data.found_posts
            this.resultsData.posts = data.posts
            this.resultsData.result_text = data.result_text
            this.resultsData.result_all_text = data.result_all_text

            if (this.$refs.result instanceof HTMLElement) {
                TweenMax.to(
                    this.$refs.result,
                    1,
                    {
                        yPercent: 0,
                        ease: Power4.easeOut,
                        onStartScope: this,
                        onStart() {
                            this.loading = false
                        }
                    }
                )
            }
        },

        getCurrentQueryString() {
            return this.action + '?qstring=' + this.query
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const MainSearch = Vue.extend(mainSearchConfiguration)

/**
 * Module export
 */
export default MainSearch

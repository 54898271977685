/**
 * @file application.js
 * @description Application class
 */

import TheatreDuChatelet from '~/theatre-du-chatelet'

// Disable tips on production
Vue.config.productionTip = false

if (!window.$) {
    console.log('------------')
    window.$ = window.jQuery
}

export class Application {
    /**
     * Application constructor
     * @return {Application}
     */
    constructor() {
        /**
         * Custom props
         */
        this.mainVueInstance = false

        /**
         * Load stylesheets
         */
        this.startApp()

        return this
    }

    /**
     * Temporary load stylesheets
     * @return {Void}
     */
    loadStylesheets() {
        let stylesheets = document.querySelectorAll('.app-stylesheet')

        if (module.hot) {
            // Load plugins stylesheets
            _.each(
                stylesheets,
                stylesheet => {
                    stylesheet.setAttribute('rel', 'stylesheet')
                    stylesheet.removeAttribute('as')
                }
            )

            // Start app
            this.startApp()
        } else {
            // Load all stylesheets
            _.each(
                stylesheets,
                stylesheet => {
                    stylesheet.setAttribute('rel', 'stylesheet')
                    stylesheet.removeAttribute('as')

                    if (stylesheet.getAttribute('id') === 'tdc/stylesheet') {
                        stylesheet.addEventListener(
                            'load',
                            () => {
                                this.startApp()
                            }
                        )
                    }
                }
            )
        }
    }

    /**
     * Start app
     * Unload loader & create vue instance
     * @return {Void}
     */
    startApp() {
        document.querySelector('.loader-progress div') instanceof HTMLElement &&
            document.querySelector('.loader-progress div').classList.remove('active')

        this.createVueInstance()
    }

    /**
     * Create main vue instance
     * @return {Void}
     */
    createVueInstance() {
        // Application instance
        this.mainVueInstance = new TheatreDuChatelet('theatre-du-chatelet')
    }
}

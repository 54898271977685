var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "visual-guide-component" }, [
    _c("div", { staticClass: "visual-guide" }, [
      _vm.vertical
        ? _c("div", { staticClass: "vertical-guide" }, [
            _c("div", { staticClass: "container" }, [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.column, function(item) {
                  return _c("div", { staticClass: "col-1" }, [_c("div")])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.horizontal
        ? _c(
            "div",
            { staticClass: "horizontal-guide" },
            _vm._l(_vm.row, function(item) {
              return _c("div", { staticClass: "col-1" }, [_c("div")])
            }),
            0
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "visual-guide-toggler" }, [
      _c("p", [_vm._v("Dev. tools")]),
      _vm._v(" "),
      _c(
        "button",
        {
          class: { active: _vm.vertical },
          attrs: { "aria-hidden": "true" },
          on: {
            click: function($event) {
              return _vm.toggle(1)
            }
          }
        },
        [_vm._v("v-marks")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          class: { active: _vm.horizontal },
          attrs: { "aria-hidden": "true" },
          on: {
            click: function($event) {
              return _vm.toggle(2)
            }
          }
        },
        [_vm._v("8px marks")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
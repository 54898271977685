/**
 * @file event-galerie.js
 * @description Event galerie
 */

import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'
import { EVENTS } from '~/config/constants'

/**
 * Event galerie configuration
 * @type {Object}
 */
let eventGalerieConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'event-galerie',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        start: {
            type: Number,
            default: 0
        }
    },

    /**
     * Component data
     * @return {Object}
     */
    data() {
        const data = { active: 0 }

        return data
    },

    /**
     * Component data watchers
     * @type {Object}
     */
    watch: {
        /**
         * Active carousel element
         * @return {Void}
         */
        active() {
            this.unloadVideos()
        }
    },

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperElement: '.event-galerie',
    swiperConfig: {
        wrapperClass: 'event-galerie-wrapper',
        slideClass: 'event-galerie-item',
        slidesPerView: 1
    },

    /**
     * Component mounted handler
     * @return {Void}
     */
    mounted() {
        this.attachCarouselEventHandler()
        this.$event.$on(EVENTS.WINDOW_RESIZE, this.updateGalleryItems)
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Attach carousel event handler
         * @return {Void}
         */
        attachCarouselEventHandler() {
            if (this.carouselInstance) {
                this.carouselInstance
                    .on(
                        'slideChange',
                        () => {
                            this.active = this.carouselInstance.activeIndex
                        }
                    )

                // .on('progress', this.unloadVideos);
            }
        },

        /**
         * Unload videos
         * @return {Void}
         */
        unloadVideos() {
            setTimeout(
                () => {
                    $('.thumb', this.$el).each(
                        function() {
                            // Remove player ready
                            $(this)
                                .removeClass('player-load')
                                .removeClass('player-ready')

                            // Destroy video player
                            $('.player', this).empty()
                        }
                    )
                },
                300
            )
        },

        /**
         * Update gallery items
         * @return {Void}
         */
        updateGalleryItems() {
            if (navigator.userAgent.match(/firefox/i)) {
                $('img.gallery').each(
                    function() {
                        const figure = $(this).parents('figure')

                        figure.width($(this).width())
                    }
                )
            }
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const EventGalerie = Vue.extend(eventGalerieConfiguration)

/**
 * Module export
 */
export default EventGalerie

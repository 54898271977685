import { render, staticRenderFns } from "./search.vue?vue&type=template&id=13cc16b4&"
import script from "./search.js?vue&type=script&lang=js&"
export * from "./search.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/www.chatelet.com/release/web/app/themes/theatre-du-chatelet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13cc16b4')) {
      api.createRecord('13cc16b4', component.options)
    } else {
      api.reload('13cc16b4', component.options)
    }
    module.hot.accept("./search.vue?vue&type=template&id=13cc16b4&", function () {
      api.rerender('13cc16b4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/scripts/components/search.vue"
export default component.exports
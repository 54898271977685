/**
 * @file events.js
 * @description Events manager
 */

import ScrollMagic from 'scrollmagic'
import { TweenLite } from 'gsap'
import { EVENTS } from '~/config/constants'

/**
 * Events manager configuration
 * @type {Object}
 */
let eventsConfiguration = {
    /**
     * Component data
     * @return {Object}
     */
    data() {
        const data = {
            screen: {
                width: 0,
                height: 0
            }
        }

        return data
    },

    /**
     * Component created
     * @return {Void}
     */
    created() {
        this.scrollController = new ScrollMagic.Controller()
        this.appScrollController = new ScrollMagic.Controller({ container: '#theatre-du-chatelet' })

        this.setupHandlers()
        this.setWindowSizes()
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Set up handler
         * @return {Void}
         */
        setupHandlers() {
            let handle = { resize: 0 }

            window.addEventListener(
                'resize',
                () => {
                    this.dispatch(EVENTS.WINDOW_RESIZE_IMMEDIATE)
                    this.setWindowSizes()

                    TweenLite.to(
                        handle,
                        0.25,
                        {
                            resize: 0.25,
                            onCompleteScope: this,
                            overwrite: true,
                            onComplete() {
                                this.dispatch(EVENTS.WINDOW_RESIZE)
                                handle.resize = 0
                            }
                        }
                    )
                }
            )

            /**
             * Key handler
             */
            document.addEventListener(
                'keyup',
                event => {
                    const keyCode = event.keyCode || event.which

                    // escape
                    if (keyCode && keyCode === 27) {
                        this.dispatch(EVENTS.KEY_UP_ESCAPE)
                    }
                }
            )
        },

        /**
         * Set window sizes
         */
        setWindowSizes() {
            this.screen.width = window.innerWidth
            this.screen.height = window.innerHeight
            document.documentElement.style.setProperty('--viewport-h', `${ this.screen.height }px`)
        },

        /**
         * Dispatch event
         * @param  {String} name Event name
         * @param {Any} params Additional params
         * @return {Void}
         */
        dispatch(name, params = false) {
            this.$emit(name, params)
        }
    }
}

/**
 * Create vue instance
 * @type {Vue}
 */
const eventsComponent = new Vue(eventsConfiguration)

/**
 * Module export
 */
export default eventsComponent

/**
 * @file events-filter.js
 * @description Events list filter
 */

import BasicMixin from '~/mixins/basic'
import Events from '~/utils/events-list'

/**
 * Component configuration
 * @type {Object}
 */
let eventsListFilterConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'events-filter',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        tous: {
            type: String,
            default: 'Tous'
        }
    },

    /**
     * Component computed props
     * @type {Object}
     */
    computed: {
        categories: () => Events.categories,
        selectedCategories: () => Events.selectedCategories
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Selected category
         * @param  {String} category
         * @return {Boolean}
         */
        selected(category) {
            return Events.selected(category)
        },

        /**
         * Toggle categories
         * @param {String} category
         */
        toggleSelected(category = false) {
            if (category) {
                // Toggle selected
                Events.toggleSelectedCategory(category)
            } else {
                // Reset selection
                Events.resetSelectedCategories()
            }
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const EventsFilter = Vue.extend(eventsListFilterConfiguration)

/**
 * Module export
 */
export default EventsFilter

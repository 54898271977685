export const TYPE_SIZE = {
    SMALL: 0.5,
    NORMAL: 1,
    BIG: 2
}

export const TYPE_PATTERN = {
    RECT: 1,
    CIRCLE: 2
}

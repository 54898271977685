var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "section-basic section-search" }, [
    _c("header", [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row row--items-center" }, [
          _c("div", { staticClass: "col-10" }, [
            _c("h2", [_vm._v(_vm._s(_vm.title))])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search-list-filter" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "search-list-filter-carousel" }, [
            _c(
              "ul",
              { staticClass: "search-list-filter-wrapper" },
              _vm._l(_vm.request.filters, function(filter, index) {
                return _c("li", { staticClass: "search-list-filter-item" }, [
                  _c(
                    "button",
                    {
                      class: {
                        active: _vm.filterHasSelection(index),
                        selected: _vm.activeFilter === index
                      },
                      attrs: { "aria-label": filter.label },
                      on: {
                        click: function($event) {
                          return _vm.activateFilter(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(filter.label))]
                  )
                ])
              }),
              0
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "events-list-filter" }, [
        _c(
          "div",
          { staticClass: "container" },
          _vm._l(_vm.request.filters, function(filter, index) {
            return _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeFilter === index,
                    expression: "activeFilter === index"
                  }
                ],
                key: filter.id,
                staticClass: "events-filter"
              },
              _vm._l(filter.content, function(item, key) {
                return _c("li", [
                  _c(
                    "button",
                    {
                      class: { active: item.active },
                      attrs: { "aria-label": item.label },
                      on: {
                        click: function($event) {
                          return _vm.toggleFilterItem(index, key)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.label))]
                  )
                ])
              }),
              0
            )
          }),
          0
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "search-loader", class: { loading: _vm.loading } },
      [
        _c("div", { staticClass: "loader-content" }, [
          _c("svg", { attrs: { viewport: "0 0 20 20" } }, [
            _c("circle", { attrs: { cx: "10", cy: "10", r: "9" } })
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "result",
        staticClass: "search-result-outer",
        class: {
          expanded:
            _vm.request.results &&
            _vm.request.results.datas &&
            _vm.request.results.datas.length
        }
      },
      [
        _c(
          "div",
          { ref: "list", staticClass: "search-result-wrapper" },
          [
            _c("home-filter-carousel", {
              attrs: {
                noresultwithsuggestions: _vm.noresultwithsuggestions,
                noresults: _vm.noresults,
                isdefault: _vm.request.results.isdefault,
                "have-results": _vm.request.results.haveResults,
                "is-suggestion": _vm.request.results.isSuggestion,
                events: _vm.request.results.datas
              }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/* global appLanguage */
/**
 * @file agenda.js
 * @description Agenda
 */

import axios from 'axios'
import { TweenMax, Power4 } from 'gsap'
import VCalendar from 'v-calendar'
import { EVENTS } from '~/config/constants'
import store from '~/store/main'
import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'

let lang = appLanguage || 'fr'

/**
 * Vue calendar plugin
 */
Vue.use(
    VCalendar,
    {
        firstDayOfWeek: 2,
        locale: lang
    }
)

/**
 * Component configurations
 * @type {Object}
 */
let agendaConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'agenda',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    /**
     * Carousel configurations
     */
    swiperElement: '.month-carousel',
    swiperConfig: {
        wrapperClass: 'month-wrapper',
        slideClass: 'month-item',
        setWrapperSize: false,
        slidesPerView: 'auto',
        breakpoints: {
            767: { slidesPerView: 1 }
        }
    },
    swiperNavigation: {
        nextEl: '.month-next',
        prevEl: '.month-prev'
    },

    /**
     * Component props
     * @type {Object}
     */
    props: {
        /**
         * Component initial configurations
         * @type {Object}
         */
        configuration: { type: Object },
        ajaxurl: { type: String },
        textvalider: { type: String },
        textannuler: { type: String },
        textfiltrer: { type: String },
        textfrom: { type: String },
        textto: { type: String },
        textnoresult: { type: String },
        textcomplet: { type: String },
        textreserver: { type: String },
        textlastplaces: { type: String },
        textlisteattente: { type: String },
        lang: { type: String },

        /**
         * Date picker styles
         * @type {Object}
         */
        pickerStyles: {
            type: Object,
            default() {
                return {
                    wrapper: {
                        backgroundColor: 'none',
                        border: 'none'
                    }
                }
            }
        }
    },

    /**
     * Component data
     * @return {Object}
     */
    data() {
        const data = {
            pickerAttributes: [],
            pickerFormats: { weekdays: 'W' },

            /**
             * Request data
             */
            request: false,

            /**
             * Results
             */
            results: false,

            /**
             * Filter data
             */
            activeDateInput: 0,
            activeFilter: false,
            filterSilentReset: true
        }

        return data
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        /**
         * Visible
         * @return {Boolean}
         */
        visible: () => store.state.agenda,

        /**
         * Stored filters
         * @return {Boolean}
         */
        storedFilters: () => store.state.agendaFilters,

        /**
         * Stored calendar
         * @return {Boolean}
         */
        storedCalendar: () => store.state.agendaCalendar,

        /**
         * Start date
         * @return {String}
         */
        startDate() {
            let startDate = this.pickerAttributes[0]

            return startDate ? [
                this.lang === 'fr' ? this.leadingZero(startDate.dates.getDate()) : this.leadingZero(startDate.dates.getMonth() + 1),
                this.lang === 'fr' ? this.leadingZero(startDate.dates.getMonth() + 1) : this.leadingZero(startDate.dates.getDate()),
                startDate.dates.getFullYear()
            ].join('/') : ''
        },

        /**
         * End date
         * @return {String}
         */
        endDate() {
            let endDate = this.pickerAttributes[1]

            return endDate ? [
                this.lang === 'fr' ? this.leadingZero(endDate.dates.getDate()) : this.leadingZero(endDate.dates.getMonth() + 1),
                this.lang === 'fr' ? this.leadingZero(endDate.dates.getMonth() + 1) : this.leadingZero(endDate.dates.getDate()),
                endDate.dates.getFullYear()
            ].join('/') : ''
        },

        /**
         * Top navigation label
         * @return {String}
         */
        mobileNavigationLabel() {
            const label = typeof this.activeFilter !== 'boolean' ? this.request.filters[this.activeFilter].label : ''

            return label
        },

        /**
         * Active season
         * @return {Object}
         */
        activeSeason() {
            return _.find(this.request.seasons, { selected: true })
        },

        /**
         * Active month index in active season
         * @return {Number}
         */
        activeSeasonSelectedMonthIndex() {
            return _.findIndex(this.activeSeason.months, { active: true })
        },

        /**
         * Active month in active season
         * @return {Number}
         */
        activeSeasonSelectedMonth() {
            return _.find(this.activeSeason.months, { active: true })
        },

        /**
         * Min date
         * @return {Date}
         */
        minDate() {
            return new Date(this.activeSeason.start)
        },

        /**
         * Max date
         * @return {Date}
         */
        maxDate() {
            return new Date(this.activeSeason.end)
        },

        /**
         * From page
         * @return {Object}
         */
        fromPage() {
            let currentCalendarPage = new Date(this.activeSeason.start)

            return {
                month: currentCalendarPage.getMonth() + 1,
                year: currentCalendarPage.getFullYear()
            }
        },

        /**
         * Enable reset filter
         * @return {Boolean}
         */
        enableResetFilter() {
            let enabled = false

            _.each(
                this.request.filters,
                filter => {
                    if (
                        (filter.type === 'list' && _.filter(filter.content, { 'active': true }).length) ||
                        (filter.type === 'date' && this.pickerAttributes.length)
                    ) {
                        enabled = true
                    }
                }
            )

            return enabled
        }
    },

    /**
     * Data watchers
     * @type {Object}
     */
    watch: {
        /**
         * Visibility watcher
         * @return {Void}
         */
        visible(visible) {
            this.toggle(visible)
        },

        /**
         * Picker attributes watcher
         * Auto fill request timespan
         * @param  {Array} value
         * @return {Void}
         */
        pickerAttributes(dates) {
            if (dates.length) {
                _.each(
                    dates,
                    (date, index) => {
                        if (index === 0) {
                            this.request.filters.timespan.content.from = date.dates.toLocaleDateString('en-EN')
                        } else if (index === 1) {
                            this.request.filters.timespan.content.to = date.dates.toLocaleDateString('en-EN')
                        }
                    }
                )
            } else {
                this.request.filters.timespan.content.from = false
                this.request.filters.timespan.content.to = false
            }
        }
    },

    /**
     * Created event handler
     * @return {Void}
     */
    created() {
        this.filterVisible = false
        this.filterContent = false
        this.monthChangeTriggerRequest = true
        this.defaultMonthCarousel = false
        this.agendaRequest = false
        this.cancelToken = axios.CancelToken
        this.default = this.configuration

        this.buildRequestData()
        this.resetResultsData()

        this.$event.$on(EVENTS.WINDOW_RESIZE, this.updateFilterVisibilityState)
    },

    /**
     * Component mounted handler
     * @return {Void}
     */
    mounted() {
        this.initialize()
        this.setupMonthCarousel()
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Set active season
         * @param {Number} index]
         */
        setActiveSeason(index) {
            this.monthChangeTriggerRequest = false

            // Set active season
            _.each(
                this.request.seasons,
                (season, key) => {
                    season.months = _.cloneDeep(this.default.seasons[key].months)
                    season.selected = key === index
                }
            )

            // Reset filter
            this.resetFilterElements()

            // Carousel update
            this.resetCarouselPosition(0)
            this.defaultMonthCarousel = this.activeSeasonSelectedMonthIndex

            setTimeout(
                () => {
                    this.carouselInstance.update()
                },
                100
            )

            // Start update
            this.listUpdateStart()
        },

        /**
         * Filter has selection
         * @param  {Number|Boolean} id
         * @return {Boolean}
         */
        filterHasSelection(id = false) {
            let filter = this.request.filters[id]
            let hasSelection = false

            if (filter.type === 'list') {
                hasSelection = _.filter(filter.content, { 'active': true }).length
            } else {
                hasSelection = this.pickerAttributes.length
            }

            return this.activeFilter !== id && hasSelection
        },

        /**
         * Set date
         * @param {Number} index
         * @param {Date} value
         */
        setDate(index, value) {
            let incomingDate = value

            let properties = {
                highlight: {
                    backgroundColor: '#111111',
                    width: '32px',
                    height: '32px'
                },
                contentStyle: { color: '#ffffff' }
            }

            // Update properties
            if (index) {
                properties = {
                    highlight: {
                        backgroundColor: 'rgba(0, 0, 0, .1)',
                        width: '32px',
                        height: '32px'
                    },
                    contentStyle: { color: '#111111' }
                }

                if (this.pickerAttributes.length && this.pickerAttributes[0].dates >= incomingDate) {
                    incomingDate = _.cloneDeep(this.pickerAttributes[0].dates)
                    incomingDate.setDate(incomingDate.getDate() + 1)

                    // incomingDate.setDate(this.pickerAttributes[0].dates.getDate() + 1);
                    // incomingDate = new Date(this.pickerAttributes[0].dates.getTime() + 86400000);
                }
            } else {
                if (this.pickerAttributes.length && this.pickerAttributes[1] && this.pickerAttributes[1].dates < incomingDate) {
                    this.pickerAttributes.splice(1, 1)
                }
            }

            // Update date value
            properties.dates = incomingDate

            // Set picker attribute
            this.pickerAttributes.splice(index, 1, properties)
        },

        /**
         * Update date
         * @param  {Object} day
         * @return {Void}
         */
        updateDate(day) {
            this.setDate(this.activeDateInput, new Date(day.dateTime))
        },

        /**
         * Select date input
         * @param  {Number} index
         * @return {Void}
         */
        selectDateInput(index) {
            if (index && !this.pickerAttributes.length) {
                // this.setDate(0, new Date());
                this.setDate(0, new Date(this.activeSeason.start))
            }

            this.activeDateInput = index
        },

        /**
         * Hide agenda
         * @return {Void}
         */
        hide() {
            this.$event.dispatch(EVENTS.AGENDA_HIDE)
        },

        /**
         * Close filters
         * @return {Void}
         */
        closeFilters() {
            if (this.filterVisible) {
                this.toggleFilter()
            }

            if (this.filterContentVisible) {
                this.toggleFilterContent()
            }

            /**
             * Clean unsaved filters
             */
            this.reSyncFilters()
        },

        /**
         * Initialize component elements
         * @return {Void}
         */
        initialize() {
            document.body.classList.remove('agenda-visible')

            TweenMax.set(
                this.$el,
                { xPercent: 100 }
            )
        },

        /**
         * Toggle visibility
         * @param  {Boolean} visible
         * @return {Void}
         */
        toggle(visible = true) {
            if (this.$el instanceof HTMLElement) {
                if (visible) {
                    TweenMax.fromTo(
                        this.$el,
                        1.2,
                        { xPercent: 100 },
                        {
                            xPercent: 0,
                            immediateRender: true,
                            clearProps: 'z-index',
                            ease: Power4.easeInOut,
                            onStartScope: this,
                            onStart() {
                                document.body.classList.add('agenda-visible')

                                if (this.carouselInstance) {
                                    this.carouselInstance.update()
                                }
                            }
                        }
                    )

                    TweenMax.fromTo(
                        [
                            this.$el.querySelector('.agenda-season-filter'),
                            this.$el.querySelector('.agenda-main-filter')
                        ],
                        1.2,
                        { xPercent: -100 },
                        {
                            xPercent: 0,
                            clearProps: 'transform, z-index',
                            ease: Power4.easeInOut
                        }
                    )

                    TweenMax.to(
                        this.$el.querySelector('.month-container'),
                        1.6,
                        {
                            className: '+=visible',
                            clearProps: 'all',
                            delay: 0.5,
                            ease: Power4.easeOut
                        }
                    )

                    TweenMax.staggerFromTo(
                        this.$el.querySelectorAll('.agenda-item'),
                        1.2,
                        {
                            xPercent: 20,
                            autoAlpha: 0
                        },
                        {
                            autoAlpha: 1,
                            xPercent: 0,
                            delay: 1,
                            clearProps: 'all',
                            ease: Power4.easeOut
                        },
                        0.1
                    )
                } else {
                    TweenMax.to(
                        this.$el.querySelector('.main-agenda-wrapper'),
                        1,
                        {
                            xPercent: 80,
                            autoAlpha: 0,
                            ease: Power4.easeInOut,
                            clearProps: 'all',
                            onStart() {
                                document.body.classList.remove('agenda-visible')
                            }
                        }
                    )

                    TweenMax.to(
                        this.$el,
                        1,
                        {
                            xPercent: -100,
                            ease: Power4.easeInOut,
                            onCompleteScope: this,
                            onComplete() {
                                this.globalReset()
                            }
                        }
                    )
                }
            }
        },

        /**
         * Component global reset
         * @return {Void}
         */
        globalReset() {
            // Reset main filter
            this.$el.querySelector('.agenda-main-filter').classList.remove('visible')
            this.$el.querySelector('.filter-list').classList.remove('visible')
            this.$el.querySelector('.filter-content').classList.remove('visible')
            this.$el.querySelector('.agenda-content-mask').classList.remove('visible')

            TweenMax.set(
                [
                    this.$el.querySelector('.filter-list'),
                    this.$el.querySelector('.filter-content'),
                    this.$el.querySelector('.agenda-content-mask')
                ],
                { clearProps: 'all' }
            )

            TweenMax.set(
                this.$el.querySelector('.agenda-main-filter'),
                { clearProps: 'transform' }
            )

            TweenMax.set(
                [
                    this.$el.querySelectorAll('.agenda-item'),
                    this.$el.querySelector('.month-container')
                ],
                { className: '-=visible' }
            )

            this.filterVisible = false
            this.filterContentVisible = false
            this.activeFilter = false
            this.activeDateInput = 0

            // Reset agenda item content scroll pos
            TweenMax.set(
                this.$el.querySelector('.agenda-content'),
                {
                    scrollTo: {
                        y: 0,
                        x: 0,
                        autoKill: false
                    }
                }
            )

            this.reSyncFilters()
        },

        /**
         * Set up month carousel
         * @return {Void}
         */
        setupMonthCarousel() {
            let updateTimeout = false

            this.resetCarouselPosition()

            if (this.carouselInstance) {
                this.carouselInstance
                    .on(
                        'slideChange',
                        () => {
                            if (this.monthChangeTriggerRequest) {
                                if (updateTimeout) {
                                    clearTimeout(updateTimeout)
                                }

                                updateTimeout = setTimeout(
                                    () => {
                                        /**
                                         * Clean unsaved filters
                                         */
                                        this.reSyncFilters()

                                        /**
                                         * Go get list
                                         */
                                        this.listUpdateStart()
                                    },
                                    100
                                )
                            }

                            // Rest trigger state
                            this.monthChangeTriggerRequest = true
                        }
                    )
            }
        },

        /**
         * Validate filter changes
         * @return {Void}
         */
        validateFilterChange() {
            let carouselUpdateData = false

            if (this.pickerAttributes.length) {
                const month = this.pickerAttributes[0].dates.getMonth()
                const year = this.pickerAttributes[0].dates.getFullYear()

                carouselUpdateData = {
                    month: month + 1,
                    year: year
                }
            }

            this.listUpdateStart(false, carouselUpdateData)
        },

        /**
         * List update start
         * @param  {Boolean} silentReset
         * @return {Void}
         */
        listUpdateStart(silentReset = false, carouselUpdateData = false) {
            let delay = this.filterVisible || this.filterContentVisible ? 0.5 : 0

            if (this.filterVisible) {
                this.toggleFilter()
            }

            if (this.filterContentVisible) {
                this.toggleFilterContent()
            }

            TweenMax.to(
                this.$el.querySelector('.agenda-content'),
                0.25,
                {
                    delay: delay,
                    autoAlpha: 0,
                    y: 30,
                    ease: Power4.easeOut,
                    overwrite: true,
                    onStartScope: this,
                    onCompleteScope: this,
                    onStart() {
                        this.$el.querySelector('.agenda-main-mask').classList.add('loading')
                        this.$el.querySelector('.loader').classList.add('loading')

                        // Update carousel
                        if (carouselUpdateData) {
                            this.monthChangeTriggerRequest = false
                            this.goToMonth(_.findIndex(this.activeSeason.months, carouselUpdateData))
                        }

                        // Update active month
                        _.each(
                            this.activeSeason.months,
                            (month, index) => {
                                month.active = index === this.carouselInstance.activeIndex
                            }
                        )
                    },
                    onComplete() {
                        // Do request
                        this.doRequest(silentReset)

                        // Reset agenda item content scroll pos
                        TweenMax.set(
                            this.$el.querySelector('.agenda-content'),
                            {
                                scrollTo: {
                                    y: 0,
                                    x: 0,
                                    autoKill: false
                                }
                            }
                        )
                    }
                }
            )
        },

        /**
         * List update done
         * @return {Void}
         */
        listUpdateEnd() {
            // Store filters
            this.storeAgendaFilters()

            // Hide loaders
            this.$el.querySelector('.loader').classList.remove('loading')
            this.$el.querySelector('.agenda-main-mask').classList.remove('loading')

            // Show results
            TweenMax.to(
                document.querySelector('.agenda-content'),
                1,
                {
                    autoAlpha: 1,
                    y: 0,
                    clearProps: 'all',
                    delay: 0.25,
                    ease: Power4.easeOut,
                    onCompleteScope: this,
                    onComplete() {
                        if (!this.filterSilentReset) {
                            this.filterSilentReset = this.activeSeason.default && this.activeSeasonSelectedMonthIndex === this.start && !this.enableResetFilter
                        }

                        this.monthChangeTriggerRequest = true
                        this.$event.dispatch(EVENTS.IMAGE_LOAD)
                    }
                }
            )
        },

        /**
         * List update aborted
         * @param {Error} error
         * @return {[type]}
         */
        listUpdateAborted(reason) {
            if (reason !== 'Agenda update aborted') {
                // this.results = [];
                this.listUpdateEnd()
            }
        },

        /**
         * Perform request
         * @param {Boolean} silentReset
         * @return {Void}
         */
        doRequest(silentReset) {
            let params = new FormData()
            let requestparams = _.cloneDeep(this.request)

            params.append('data', JSON.stringify(requestparams))
            params.append('action', 'post_agenda_filter')

            if (this.agendaRequest) {
                this.agendaRequest.cancel('Agenda update aborted')
            }

            this.agendaRequest = this.cancelToken.source()

            // Cleanup result
            this.results = []

            axios
                .post(
                    this.ajaxurl,
                    params,
                    { cancelToken: this.agendaRequest.token }
                )
                .then(
                    response => {
                        if (response && response.data && response.status === 200) {
                            this.results = _.cloneDeep(response.data.results)
                            this.filterSilentReset = silentReset
                            this.listUpdateEnd()
                        }
                    }
                )
                .catch(
                    error => {
                        /**
                         * @todo  error handler
                         */
                        this.listUpdateAborted(error.message)
                    }
                )
        },

        /**
         * Go to month
         * @param  {Number} index
         * @return {Void}
         */
        goToMonth(index) {
            if (this.carouselInstance) {
                this.carouselInstance.slideTo(index, 1500)
            }
        },

        /**
         * Store agenda filters
         * @return {Void}
         */
        storeAgendaFilters() {
            /**
             * Store filters
             * Create clone to remove reactivity
             */
            store.dispatch('setAgendaFilters', _.cloneDeep(this.request.filters))
            store.dispatch('setAgendaCalendar', _.cloneDeep(this.pickerAttributes))
        },

        /**
         * Toggle filter item
         * @param  {Number} parent
         * @param  {Number} self
         * @return {Void}
         */
        toggleFilterItem(parent, self) {
            this.request.filters[parent].content[self].active = !this.request.filters[parent].content[self].active
        },

        /**
         * Sync filters
         * Load stored filters from vuex storage
         * @return {Void}
         */
        reSyncFilters() {
            this.request.filters = _.cloneDeep(this.storedFilters)
            this.pickerAttributes = _.cloneDeep(this.storedCalendar)
        },

        /**
         * Build request data
         * @return {Void}
         */
        buildRequestData() {
            /**
             * Build request data
             * Clone initial configuration
             */
            this.request = _.cloneDeep(_.omit(this.default, ['results']))
            this.defaultMonthCarousel = this.activeSeasonSelectedMonthIndex
            this.storeAgendaFilters()
            this.$event.dispatch(EVENTS.IMAGE_LOAD)
        },

        /**
         * Reset request filters
         * @return {Void}
         */
        resetRequestFilters() {
            this.request.filters = _.cloneDeep(this.default.filters)
        },

        /**
         * Reset date picker
         * @return {Void}
         */
        resetDatePicker() {
            // Clear date picker
            this.pickerAttributes.splice(0, this.pickerAttributes.length)
            this.activeDateInput = 0
        },

        /**
         * Reset filter elements
         * @return {Void}
         */
        resetFilterElements() {
            this.resetRequestFilters()
            this.resetDatePicker()
            this.storeAgendaFilters()
        },

        /**
         * Reset results data
         * @return {Void}
         */
        resetResultsData() {
            this.results = _.cloneDeep(this.default.results)
        },

        /**
         * Reset carousel position
         * @return {Void}
         */
        resetCarouselPosition(duration = 0) {
            if (this.carouselInstance && this.activeSeasonSelectedMonthIndex > -1) {
                this.carouselInstance.slideTo(this.activeSeasonSelectedMonthIndex, duration)
            }
        },

        /**
         * Reset filter
         * @todo  :)
         * @return {Void}
         */
        resetFilter() {
            if (this.enableResetFilter) {
                // Block carousel change update trigger
                this.monthChangeTriggerRequest = false

                // Reset filter
                this.resetFilterElements()

                // Reload or hide components
                if (this.filterSilentReset) {
                    if (this.filterVisible) {
                        this.toggleFilter()
                    }

                    if (this.filterContentVisible) {
                        this.toggleFilterContent()
                    }

                    this.monthChangeTriggerRequest = true
                } else {
                    // Reset
                    _.each(
                        this.activeSeason.months,
                        (month, index) => {
                            month.active = index === this.defaultMonthCarousel
                        }
                    )

                    this.carouselInstance.slideTo(this.defaultMonthCarousel, 1000)
                    this.listUpdateStart(true)
                }
            }
        },

        /**
         * Activate filter
         * @param  {Number} id
         * @return {Void}
         */
        activateFilter(id = false) {
            if (!this.filterVisible || id === this.activeFilter) {
                this.toggleFilter()
            }

            if (!this.filterContentVisible || id === this.activeFilter) {
                this.toggleFilterContent()
            }

            this.activeFilter = id || _.keys(this.request.filters)[0]
            this.activeDateInput = 0
        },

        /**
         * Toggle filter main list
         * @return {Void}
         */
        toggleFilter() {
            let className = '+=visible'

            if (this.filterVisible) {
                className = '-=visible'
            }

            this.filterVisible = !this.filterVisible

            TweenMax.to(
                [
                    this.$el.querySelector('.agenda-main-filter'),
                    this.$el.querySelector('.filter-list'),
                    this.$el.querySelector('.agenda-content-mask')
                ],
                0.8,
                {
                    className: className,
                    clearProps: 'transform, opacity',
                    ease: Power4.easeInOut
                }
            )
        },

        /**
         * Toggle filter list content
         * @return {Void}
         */
        toggleFilterContent() {
            let className = '+=visible'

            if (this.filterContentVisible) {
                className = '-=visible'
            }

            TweenMax.to(
                this.$el.querySelector('.filter-content'),
                0.8,
                {
                    className: className,
                    clearProps: 'transform, opacity',
                    ease: Power4.easeInOut,
                    onCompleteScope: this,
                    onComplete() {
                        if (this.filterContentVisible) {
                            this.activeFilter = false
                        }

                        this.filterContentVisible = !this.filterContentVisible
                        this.activeDateInput = 0
                    }
                }
            )
        },

        /**
         * Update filter visibility state
         * @return {Void}
         */
        updateFilterVisibilityState() {
            if (this.filterVisible && !this.filterContentVisible) {
                if (window.innerWidth > 768) {
                    this.toggleFilter()
                }
            }
        },

        openWindow(link) {
            window.open(link, '_blank')
        },

        /**
         * Toggle representation
         * @param  {Number} parent
         * @param  {Number} perfo
         * @return {Void}
         */
        toggleRepresentation(parent, perfo) {
            _.each(
                this.results[parent].performances,
                (performance, index) => {
                    if (index === perfo) {
                        performance.selected = true
                    } else {
                        performance.selected = false
                    }
                }
            )
        },

        /**
         * Is same date
         * @param  {Number}  resultIndex
         * @return {Boolean}             [description]
         */
        isSameDate(resultIndex) {
            let hasBefore = this.results[resultIndex - 1]

            return hasBefore && hasBefore.date === this.results[resultIndex].date
        },

        /**
         * Book action action
         * @param  {String} target
         * @return {Void}
         */
        book(target) {
            window.open(target)
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const Agenda = Vue.extend(agendaConfiguration)

/**
 * Module export
 */
export default Agenda

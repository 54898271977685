/**
 * @file article-carousel.js
 * @description Article carousel
 */

import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'

/**
 * Article carousel configuration
 * @type {Object}
 */
let articleCarouselConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'article-carousel',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [BasicMixin, CarouselMixin],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        start: {
            type: Number,
            default: 0
        },
        centered: {
            type: Number,
            default: 0
        }
    },

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperConfig: {
        slidesPerView: 3,
        breakpoints: {
            1279: { slidesPerView: 2 },
            767: { slidesPerView: 1 }
        }
    },

    swiperCenteredAt: 2
}

/**
 * Register component
 * @type {Vue}
 */
const ArticleCarousel = Vue.extend(articleCarouselConfiguration)

/**
 * Module export
 */
export default ArticleCarousel

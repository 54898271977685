/**
 * @file magazine-hero-carousel.js
 * @description Magazine hero carousel
 */

import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'

/**
 * Event carousel configuration
 * @type {Object}
 */
let magazineHeroCarouselConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'magazine-hero-carousel',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        start: {
            type: Number,
            default: 0
        }
    },

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperElement: '.magazine-hero-carousel',
    swiperConfig: {
        wrapperClass: 'magazine-hero-wrapper',
        slideClass: 'magazine-item',
        slidesPerView: 1
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Do action
         * @return {Void}
         */
        doAction(target) {
            if (target) {
                window.location.href = target
            }
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const MagazineHeroCarousel = Vue.extend(magazineHeroCarouselConfiguration)

/**
 * Module export
 */
export default MagazineHeroCarousel

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "artistes-list-filter", class: { visible: _vm.visible } },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-10 col-lg-6 col-offset-1 col-offset-md-4" },
            [
              _c("div", { staticClass: "filter-content" }, [
                _c(
                  "div",
                  { staticClass: "filter-content-inner" },
                  [
                    _c(
                      "transition-group",
                      { attrs: { name: "list", tag: "ul" } },
                      _vm._l(_vm.selectedCategories, function(category, index) {
                        return _c(
                          "li",
                          {
                            key: category.id,
                            on: {
                              click: function($event) {
                                return _vm.updateFilter(category.id)
                              }
                            }
                          },
                          [
                            category.id
                              ? [_vm._v(_vm._s(category.name))]
                              : _vm._e(),
                            _vm._v(" "),
                            index === _vm.selectedCategories.length - 1
                              ? _c("svg", { attrs: { viewBox: "0 0 25 25" } }, [
                                  _c("path", {
                                    attrs: {
                                      fill: "#fff",
                                      "fill-rule": "evenodd",
                                      d:
                                        "M2.966 10a9.44 9.44 0 0 1 1.079-2.463 9.66 9.66 0 0 1 2.073-2.39 9.433 9.433 0 0 1 2.776-1.573A9.346 9.346 0 0 1 12.139 3a9.26 9.26 0 0 1 3.687.752 9.644 9.644 0 0 1 3.033 2.019 9.441 9.441 0 0 1 2.028 3.023c.502 1.171.752 2.4.752 3.686a9.29 9.29 0 0 1-.752 3.691 9.544 9.544 0 0 1-2.028 3.038 9.627 9.627 0 0 1-3.033 2.034 9.217 9.217 0 0 1-3.687.757c-2.177 0-4.126-.673-5.848-2.019l1.81-2.325c1.202.923 2.547 1.385 4.038 1.385a6.386 6.386 0 0 0 3.286-.88 6.511 6.511 0 0 0 2.38-2.39 6.44 6.44 0 0 0 .875-3.29c0-.885-.173-1.73-.519-2.54a6.585 6.585 0 0 0-1.395-2.087 6.585 6.585 0 0 0-2.088-1.395 6.378 6.378 0 0 0-2.539-.52c-1.444 0-2.74.427-3.884 1.282A6.475 6.475 0 0 0 6.084 10H9l-4.5 7L0 10h2.966z"
                                    }
                                  })
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/**
 * @file event-related-carousel.js
 * @description Event related
 */

import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'

/**
 * Event carousel configuration
 * @type {Object}
 */
let eventRelatedCarouselConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'event-related-carousel',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        start: {
            type: Number,
            default: 0
        }
    },

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperConfig: {
        slidesPerView: 1,
        breakpoints: {
            1023: { slidesPerView: 2 },
            767: { slidesPerView: 1 }
        }
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Do action
         * @return {Void}
         */
        doAction(target) {
            if (target) {
                window.location.href = target
            }
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const EventRelatedCarousel = Vue.extend(eventRelatedCarouselConfiguration)

/**
 * Module export
 */
export default EventRelatedCarousel

/**
 * @file video-teaser.js
 * @description Video teaser
 */

import store from '~/store/main'
import BasicMixin from '~/mixins/basic'
import { TweenMax, Power4 } from 'gsap'

let videoTeaserConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'video-teaser',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin
    ],

    /**
     * Component props
     */
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        video: {
            type: [String, Boolean],
            default: false
        },
        lang: {
            type: String,
            default: ''
        }
    },

    /**
     * Component main storage
     */
    store,

    /**
     * Component data
     * @return {Object}
     */
    data() {
        const data = { currentLang: this.lang }

        return data
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        teaser: () => store.state.teaser,
        teaserint: () => store.state.teaserint,
        active() {
            const isTeaser = this.teaserint[this.currentLang] ? this.teaserint[this.currentLang] : false

            return isTeaser && this.visible
        }
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Play teaser
         * @return {Void}
         */
        play() {
            if (this.video) {
                const playerContainer = $('.player', this.$el)
                const player = document.createElement('iframe')
                const playerInsert = { handle: 0.5 }

                // Video load
                this.$el.classList.add('player-load')

                // Insert player
                TweenMax.to(
                    playerInsert,
                    playerInsert.handle,
                    {
                        handle: 0,
                        onCompleteScope: this,
                        onComplete() {
                            // Build youtube frame
                            player.setAttribute('src', '//www.youtube.com/embed/' + this.video + '?mute=1&autoplay=1&modestbranding=1&showinfo=0&cc_load_policy=0&iv_load_policy=3&autohide=1')
                            player.setAttribute('width', 1920)
                            player.setAttribute('height', 1080)
                            player.setAttribute('type', 'text/html')
                            player.setAttribute('frameborder', '0')
                            player.setAttribute('allow', 'autoplay')
                            player.setAttribute('allowfullscreen', 'true')
                            player.setAttribute('allowtransparency', 'true')

                            // Handle frame load
                            player.addEventListener(
                                'load',
                                () => {
                                    this.$el.classList.add('player-ready')
                                }
                            )

                            // Append frame
                            if (playerContainer) {
                                playerContainer.append(player)
                            }
                        }
                    }
                )
            }
        },

        /**
         * Dismiss teaser
         * @return {Void}
         */
        dismiss(lang) {
            this.setTeaserStorageState(this.generateLocalStorageToken(true), lang)
            this.hide(
                () => {
                    store.commit('toggleTeaserInt', {
                        lang: 'en',
                        value: false
                    })
                }
            )
        },

        /**
         * Dismiss teaser
         * @return {Void}
         */
        closeTeaser(lang = 'en') {
            this.setTeaserStorageState(this.generateLocalStorageToken(), lang)
            this.hide(
                () => {
                    store.commit('toggleTeaserInt', {
                        lang: 'en',
                        value: false
                    })
                }
            )
        },
        /**
         * Dismiss teaser
         * @return {Void}
         */
        setTeaserStorageState(duration, lang = 'fr') {
            let storageData = {}

            if (localStorage.hideteaser) {
                storageData = JSON.parse(localStorage.hideteaser)
            } else {
                storageData[lang] = {}
            }
            storageData[lang] = {
                value: true,
                duration: duration
            }
            localStorage.hideteaser = JSON.stringify(storageData)
        },

        /**
        * generateLocalStorageToken for30Days
        * @return {Void}
        */
        generateLocalStorageToken(formonth = false) {
            const today = new Date()

            if (formonth === false) {
                return today.setDate(today.getDate() + 1)
            } else {
                return today.setDate(today.getDate() + 30)
            }
        },

        /**
         * Hide teaser
         * @return {Void}
         */
        hide(callback = false) {
            TweenMax.to(
                this.$el,
                0.5,
                {
                    autoAlpha: 0,
                    ease: Power4.easeOut,
                    onCompleteScope: this,
                    onComplete() {
                        // Remove player state classnames
                        $(this.$el).removeClass('player-load player-ready')

                        // Destroy youtube player
                        $('.player iframe', this.$el).remove()

                        // Callback
                        if (callback && callback instanceof Function) {
                            callback()
                        }
                    }
                }
            )
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const VideoTeaser = Vue.extend(videoTeaserConfiguration)

/**
 * Module export
 */
export default VideoTeaser

/**
 * @file carousel.js
 * @description Basic mixin
 */

import Swiper from 'swiperslider'
import { EVENTS, SWIPER } from '~/config/constants'

/**
 * Shared configuration
 * @type {Object}
 */
const carouselMixin = {
    /**
     * Component props
     * @type {Object}
     */
    props: {
        start: {
            type: Number,
            default: 0
        }
    },

    /**
     * Component options
     * @type {Object}
     */
    swiperElement: '.events-carousel',
    swiperPagination: '.progression',
    swiperPaginationType: 'progress',
    swiperPaginationConfig: { draggable: true },
    swiperNavigation: {
        prevEl: '.progress-navigation .prev',
        nextEl: '.progress-navigation .next'
    },
    swiperConfig: {},
    swiperCenteredAt: 1,

    /**
     * Mounted event handler
     * @return {Void}
     */
    mounted() {
        this.setupCarousel()
        this.$event.$on(EVENTS.WINDOW_RESIZE, this.updateCarouselElement)
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Set up slider
         * @return {Void}
         */
        setupCarousel() {
            const carousel = this.$el.querySelector(this.$options.swiperElement)

            if (carousel && carousel instanceof HTMLElement) {
                // Merge config
                let configuration = _.clone(SWIPER)

                if (this.$options.swiperPaginationType && this.$options.swiperPaginationType === 'progress') {
                    let scrollbar = { el: this.$el.querySelector(this.$options.swiperPagination) }

                    _.assign(
                        scrollbar,
                        this.$options.swiperPaginationConfig
                    )

                    _.assign(
                        this.$options.swiperConfig,
                        { scrollbar: scrollbar }
                    )
                } else {
                    _.assign(
                        this.$options.swiperPaginationConfig,
                        { el: this.$el.querySelector(this.$options.swiperPagination) }
                    )

                    _.assign(
                        this.$options.swiperConfig,
                        { pagination: this.$options.swiperPaginationConfig }
                    )
                }

                if (this.$options.swiperNavigation && this.$options.swiperNavigation.prevEl && this.$options.swiperNavigation.nextEl) {
                    _.assign(
                        this.$options.swiperConfig,
                        {
                            navigation: {
                                prevEl: this.$el.querySelector(this.$options.swiperNavigation.prevEl),
                                nextEl: this.$el.querySelector(this.$options.swiperNavigation.nextEl)
                            }
                        }
                    )
                }

                _.assign(
                    configuration,
                    this.$options.swiperConfig,
                    { initialSlide: this.start }
                )

                if (this.centered && this.$el.querySelectorAll('.' + configuration.slideClass).length <= this.$options.swiperCenteredAt) {
                    carousel.classList.add('centered-element')

                    _.assign(
                        configuration,
                        { centerInsufficientSlides: true }
                    )
                }

                // Create carousel instance
                this.carouselInstance = new Swiper(carousel, configuration)
                this.carouselInstance.on('init', this.carouselInstanceInitialize)
                this.carouselInstance.init()
            }
        },

        /**
         * Do action
         * @param  {String} target
         * @return {Void}
         */
        doAction(target) {
            window.location.href = target
        },

        /**
         * Update carousel instance
         * @return {Void}
         */
        updateCarouselElement() {
            if (this.carouselInstance) {
                this.carouselInstance.update()
            }
        },

        /**
         * Carousel initialize
         * @return {Void}
         */
        carouselInstanceInitialize() {}
    }
}

/**
 * Export configuration
 */
export default carouselMixin

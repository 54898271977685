/**
 * @file entrypoint.js
 * @description Entrypoint
 */


import AnimationGSAP from 'animation.gsap'
import DebugIndicator from 'debug.indicator'
import AttrPlugin from 'gsap/AttrPlugin'
import CSSPlugin from 'gsap/CSSPlugin'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Modernizr from 'modernizr'
import { Application } from '~/application'
import { APP } from '~/config/constants'

console.info('%c%s', 'color: #fff; background: #285feb; padding: 2px 5px;', APP.NAME.toUpperCase())

/**
 * Extras
 * Prevent webpack tree shaking
 * @type {Array}
 */
/* eslint-disable */
const extras = [ ScrollToPlugin, AttrPlugin, CSSPlugin, DebugIndicator, AnimationGSAP ]
/* eslint-enable */

/**
 * Building app
 * @return {Void}
 */
const buildApp = () => {
    // Application instance
    const theatreDuChatelet = new Application()

    // Attaching namespace to window
    window.theatreDuChatelet = theatreDuChatelet || {}
}

// Scroll restoration
if (Modernizr.history && 'scrollRestoration' in window.history) {
    history.scrollRestoration = 'manual'
} else {
    window.scrollTo(0, 0)
}

/**
 * Hot reloading extra process
 */
if (module.hot) {
    // console.clear();
    console.info('%c%s', 'color: #fff; background: orange; padding: 2px 5px;', 'HOT RELOAD ENABLED')
    module.hot.accept()
}

buildApp()

/**
 * @file user-form.js
 * @description User form register
 * @author johanesa <>
 */
import BasicMixin from '~/mixins/basic'

let userFormRegisterConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'user-form-register',

    /**
    * Component props
    * @type {Object}
    */
    props: {
        individual: String,
        group: String
    },

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component data
     * @return {Object}
     */
    data() {
        let data = { type: 'individual' }

        return data
    },

    /**
     * Computed props
     * @type {Object}
     */
    computed: {
        /**
         * Compute form action target
         * @return {String}
         */
        action() {
            return this[this.type]
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const UserFormRegister = Vue.extend(userFormRegisterConfiguration)

/**
 * Module export
 */
export default UserFormRegister

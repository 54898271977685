var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-search" }, [
    _c("div", { ref: "form", staticClass: "main-search-form" }, [
      _c("div", { staticClass: "container search-container" }, [
        _c("div", { staticClass: "btn-wrapper" }, [
          _c("button", {
            staticClass: "close",
            attrs: { "aria-hidden": "true" },
            on: { click: _vm.hide }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 nopad" }, [
            _c("div", { staticClass: "search-form" }, [
              _c("form", { attrs: { action: _vm.action, method: "get" } }, [
                _c("fieldset", [
                  _c(
                    "div",
                    {
                      staticClass: "form-field",
                      class: { loading: _vm.loading }
                    },
                    [
                      _c("div", { staticClass: "form-input" }, [
                        _c("input", {
                          ref: "field",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            name: "qstring",
                            id: "s",
                            placeholder: _vm.placeholder,
                            "aria-label": _vm.placeholder
                          },
                          domProps: { value: _vm.query },
                          on: {
                            input: function($event) {
                              _vm.query = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "loader" }, [
                          _c("svg", { attrs: { viewport: "0 0 20 20" } }, [
                            _c("circle", {
                              attrs: { cx: "10", cy: "10", r: "9" }
                            })
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        attrs: { type: "submit", name: "" },
                        domProps: { value: _vm.placeholder }
                      })
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { ref: "result", staticClass: "main-search-content" }, [
      _c("div", { staticClass: "container search-container" }, [
        _c("div", { staticClass: "row row--items-center" }, [
          _c("div", { staticClass: "col-10 nopad" }, [
            _c("div", { staticClass: "search-items-preview" }, [
              _c(
                "ul",
                _vm._l(_vm.resultsData.posts, function(post, index) {
                  return _c("li", { key: index }, [
                    _c(
                      "a",
                      { attrs: { href: post.url, "aria-label": post.title } },
                      [
                        _c("div", [
                          _c("p", [
                            _c("span", { staticClass: "type" }, [
                              _vm._v(_vm._s(post.type))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(post.title))]),
                          _vm._v(" "),
                          _c("p", [_c("span", [_vm._v(_vm._s(_vm.ensavoir))])])
                        ])
                      ]
                    )
                  ])
                }),
                0
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row row--items-center" }, [
          _c("div", { staticClass: "col-10 search-bottom" }, [
            _vm.resultsData.found_posts > 0
              ? _c("p", { staticClass: "search-results-count" }, [
                  _vm._v(
                    _vm._s(_vm.resultsData.found_posts) +
                      " " +
                      _vm._s(_vm.resultsData.result_text)
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.resultsData.found_posts === 0
              ? _c("p", { staticClass: "search-results-count" }, [
                  _vm._v(_vm._s(_vm.noresults))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.resultsData.found_posts > 0
              ? _c("div", { staticClass: "search-result-more" }, [
                  _c(
                    "a",
                    {
                      staticClass: "cta-btn cta-btn-border-out cta-btn-dark",
                      attrs: {
                        href: _vm.getCurrentQueryString(),
                        "aria-label": _vm.resultsData.result_all_text
                      }
                    },
                    [
                      _c("span", { staticClass: "bg" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(_vm.resultsData.result_all_text))
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
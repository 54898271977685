/**
 * @file artistes-carousel.js
 * @description Artistes carousel
 */

import { TweenMax, Back, Power4 } from 'gsap'
import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'
import Artists from '~/utils/artists'

/**
 * Artists carousel configuration
 * @type {Object}
 */
let artistesCarouselConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'artistes-carousel',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        start: {
            type: Number,
            default: 0
        }
    },

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperElement: '.artistes-carousel',
    swiperConfig: {
        wrapperClass: 'artistes-carousel-wrapper',
        slideClass: 'artistes-carousel-item',
        slidesPerView: 3.33,
        breakpoints: {
            1024: { slidesPerView: 2 },
            479: { slidesPerView: 1.25 }
        }
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        selectedCategories() {
            return Artists.selected
        }
    },

    /**
     * Data watchers
     * @type {Object}
     */
    watch: {
        /**
         * Selected categories watcher
         * @return {Void}
         */
        selectedCategories() {
            // Toggle slides
            this.toggleSlides()
        }
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * item in category
         * @param  {Array} categories
         * @return {Boolean}
         */
        inCategory(categories) {
            let inCategory = false

            if (this.selectedCategories.length) {
                _.each(
                    categories,
                    category => {
                        if (_.includes(this.selectedCategories, category)) {
                            inCategory = true
                        }
                    }
                )
            } else {
                inCategory = true
            }

            return inCategory
        },

        /**
         * Toggle slides
         * @return {Void}
         */
        toggleSlides() {
            const activeItems = this.$el.querySelectorAll('.artistes-carousel-item.active')

            if (activeItems && activeItems.length) {
                TweenMax.staggerFromTo(
                    activeItems,
                    0.5,
                    {
                        autoAlpha: 1,
                        x: 0
                    },
                    {
                        autoAlpha: 0,
                        x: -100,
                        ease: Power4.easeInOut
                    },
                    0.1,
                    () => {
                        this.revealSelected()
                    }
                )
            } else {
                this.revealSelected()
            }
        },

        /**
         * Reveal selected
         * @return {Void}
         */
        revealSelected() {
            const items = this.$el.querySelectorAll('.artistes-carousel-item')
            const container = this.$el.querySelector('.artistes-carousel-wrapper')
            let carouselItems = _.filter(
                items,
                item => {
                    return this.inCategory($(item).data('category'))
                }
            )

            if (carouselItems.length) {
                this.updateSlideClassnames()

                $(container).slideDown(
                    {
                        duration: 500,
                        ease: 'easeInOutQuart',
                        complete: () => {
                            if (this.carouselInstance) {
                                this.carouselInstance.slideTo(0)
                                this.carouselInstance.update()
                            }

                            TweenMax.staggerFromTo(
                                carouselItems,
                                0.5,
                                {
                                    autoAlpha: 0,
                                    x: 100
                                },
                                {
                                    autoAlpha: 1,
                                    x: 0,
                                    ease: Back.easeOut.config(0.8)
                                },
                                0.1
                            )
                        }
                    }
                )
            } else {
                $(container).slideUp(
                    {
                        duration: 500,
                        ease: 'easeInOutQuart',
                        complete: this.updateSlideClassnames
                    }
                )
            }
        },

        /**
         * Update slide classnames
         * @return {Void}
         */
        updateSlideClassnames() {
            const items = this.$el.querySelectorAll('.artistes-carousel-item')

            _.each(
                items,
                item => {
                    const active = this.inCategory($(item).data('category'))

                    if (active) {
                        $(item).addClass('active')
                    } else {
                        $(item).removeClass('active')
                    }
                }
            )
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const ArtistesCarousel = Vue.extend(artistesCarouselConfiguration)

/**
 * Module export
 */
export default ArtistesCarousel

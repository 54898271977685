export const PATTERN_DEFAULT_ALPHA = 1
export const BASE_GUTTER_WIDTH = 25 // pixels
export const DEFAULT_SQUARE_SIZE = 25 // 20 * gutter
export const DEFAULT_SQUARE_SIZE_WIDTH = 35 // 20 * gutter
export const DEFAULT_SQUARE_SIZE_HEIGHT = 20 // 20 * gutter
export const CIRCLE_BRUSH_PATH = '/app/themes/theatre-du-chatelet/dist/medias/bg-circle-brush.png'
export const CIRCLE_MAX_WIDTH = 300
export const CIRCLE_MIN_WIDTH = 75
export const PATTERN_RECT_SCHEMAS = [
    [
        {
            x: 0,
            y: -4,
            w: 6,
            h: 7,
            r: -46
        },
        {
            x: 7.5,
            y: -1,
            w: 9,
            h: 4,
            r: -46
        },
        {
            x: 16,
            y: 1.5,
            w: 7,
            h: 4,
            r: 46
        },
        {
            x: 23.5,
            y: -0.5,
            w: 9,
            h: 4,
            r: 46
        },
        {
            x: -2.5,
            y: 7.5,
            w: 9,
            h: 3.6,
            r: 46
        },
        {
            x: 6.5,
            y: 5.5,
            w: 2.5,
            h: 5,
            r: -46
        },
        {
            x: 12,
            y: 6.5,
            w: 7,
            h: 3,
            r: 46
        },
        {
            x: 21,
            y: 7,
            w: 7,
            h: 3.5,
            r: -46
        },
        {
            x: 29,
            y: 9,
            w: 4,
            h: 6,
            r: -46
        },
        {
            x: -11.5,
            y: 13.5,
            w: 5,
            h: 4,
            r: -46
        },
        {
            x: -7,
            y: 19,
            w: 5.6,
            h: 2.5,
            r: -46
        },
        {
            x: -1,
            y: 17,
            w: 9,
            h: 3.5,
            r: 46
        },
        {
            x: 7,
            y: 13,
            w: 8,
            h: 3.5,
            r: -46
        },
        {
            x: 15.5,
            y: 16,
            w: 8,
            h: 5,
            r: 46
        }
    ],
    [
        {
            x: 0,
            y: -0.5,
            w: 6,
            h: 3,
            r: -46
        },
        {
            x: 9,
            y: -3.5,
            w: 2.3,
            h: 4.6,
            r: -46
        },
        {
            x: 12,
            y: -0.5,
            w: 6,
            h: 3,
            r: -46
        },
        {
            x: 19,
            y: -3,
            w: 8,
            h: 2,
            r: -46
        },
        {
            x: 22,
            y: -0.5,
            w: 7,
            h: 3,
            r: -46
        },
        {
            x: 31,
            y: -1,
            w: 4,
            h: 1.5,
            r: 46
        },
        {
            x: 28,
            y: 3,
            w: 7,
            h: 3,
            r: 46
        },
        {
            x: 0,
            y: 6,
            w: 7,
            h: 3,
            r: -46
        },
        {
            x: 7,
            y: 4,
            w: 7,
            h: 3,
            r: 46
        },
        {
            x: 15,
            y: 5.7,
            w: 6,
            h: 2.5,
            r: 46
        },
        {
            x: 23,
            y: 6,
            w: 6,
            h: 3,
            r: -46
        },
        {
            x: 6.5,
            y: 9.5,
            w: 3.2,
            h: 1.6,
            r: -134
        },
        {
            x: 10,
            y: 11,
            w: 7.8,
            h: 4,
            r: 134
        },
        {
            x: 16.5,
            y: 13,
            w: 7.8,
            h: 4,
            r: 134
        },
        {
            x: 24.5,
            y: 15,
            w: 3.5,
            h: 1.8,
            r: 134
        },
        {
            x: 27,
            y: 12,
            w: 6.7,
            h: 3,
            r: 46
        },
        {
            x: 32,
            y: 9,
            w: 1.8,
            h: 3.5,
            r: 134
        },
        {
            x: 34.5,
            y: 16,
            w: 1.8,
            h: 3.5,
            r: 134
        },
        {
            x: 37,
            y: 13,
            w: 5.5,
            h: 3.2,
            r: -46
        },
        {
            x: 5,
            y: 18,
            w: 6.5,
            h: 3.2,
            r: 46
        },
        {
            x: 13.5,
            y: 18.5,
            w: 4.5,
            h: 3,
            r: 46
        },
        {
            x: 21,
            y: 19.5,
            w: 6.5,
            h: 3,
            r: 46
        }
    ]
]

export const PATTERN_CIRC_SCHEMAS = [
    [
        {
            x: 5,
            y: 4,
            w: 200
        },
        {
            x: 30,
            y: 10,
            w: 430
        },
        {
            x: 14.5,
            y: 16.5,
            w: 345
        },
        {
            x: 3,
            y: 21,
            w: 170
        },
        {
            x: 7.5,
            y: 23,
            w: 100
        },
        {
            x: 15.5,
            y: 23.2,
            w: 170
        },
        {
            x: 31,
            y: 26,
            w: 360
        }
    ]
]

export class SquareConf {
    constructor(patternType, squareW, gutter, squareRectSchemas, circleRectSchemas) {
        this.squareW = squareW
        this.squareRectSchemas = squareRectSchemas
        this.circleRectSchemas = circleRectSchemas
        this.patternType = patternType
        this.gutter = gutter
    }
}

import * as PIXI from 'pixi.js'
import { TweenMax, Power4 } from 'gsap'

export default class extends PIXI.Container {
    constructor(size) {
        super()

        this.container = new PIXI.Container()
        this.targetW = size

        if (size && size > 0) {
            const circleShape = new PIXI.Graphics()

            circleShape.beginFill(0xFFFFFF)
            circleShape.drawCircle(0, 0, size)
            circleShape.endFill()

            this.container.addChild(circleShape)
            this.container.x = -size / 2
            this.container.y = -size / 2
            this.container.height = 0
            this.container.width = 0

            this.addChild(this.container)
        }
    }

    animate() {
        return new Promise(
            resolve => {
                TweenMax.fromTo(
                    this.container,
                    2,
                    { alpha: 0.6 },
                    {
                        width: this.targetW,
                        height: this.targetW,
                        ease: Power4.easeOut,
                        delay: Math.random() * 1.5,
                        onComplete: resolve
                    }
                )
            }
        )
    }
}

/**
 * @file artists.js
 * @description Artists utils
 */

import { TweenMax, Power4 } from 'gsap'
import { EVENTS } from '~/config/constants'

/**
 * Artists utils configuration
 * @type {Object}
 */
let artistsConfiguration = {
    /**
     * Component data
     * @return {Object}
     */
    data() {
        const data = { selected: [] }

        return data
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Update window
         * @param  {Function} onComplete
         * @return {Void}
         */
        updateWindow(onComplete) {
            console.log('👫 update viewport')
            let container = document.querySelector('.main-app-wrapper')

            // Trigger before update
            this.beforeUpdate()

            if (container.scrollTop) {
                // Scroll top before update
                TweenMax.to(
                    container,
                    1,
                    {
                        scrollTo: {
                            y: 0,
                            x: 0,
                            autoKill: false
                        },
                        ease: Power4.easeInOut,
                        onCompleteScope: this,
                        onComplete() {
                            this.windowUpdateDone(onComplete)
                        }
                    }
                )
            } else {
                // Update
                this.windowUpdateDone(onComplete)
            }
        },

        /**
         * Update selected category
         * @param {Number} id
         * @return {Void}
         */
        updateSelected(id) {
            this.updateWindow(
                () => {
                    TweenMax.to(
                        document.querySelector('.artistes-list-elements'),
                        0.3,
                        {
                            y: 50,
                            autoAlpha: 0,
                            ease: Power4.easeOut,
                            onCompleteScope: this,
                            onComplete() {
                                this.setSelected(id)
                            }
                        }
                    )
                }
            )
        },

        /**
         * Setting selected
         */
        setSelected(id) {
            this.selected.splice(0, this.selected.length)

            if (id) {
                this.selected.push(id)
            }
        },

        /**
         * Window update done
         * @param  {Function} callback
         * @return {Void}
         */
        windowUpdateDone(callback) {
            this.$emit(EVENTS.ARTIST_SCROLL_END)

            if (callback && callback instanceof Function) {
                callback()
            }
        },

        /**
         * Before window update
         * @return {Void}
         */
        beforeUpdate() {
            this.$emit(EVENTS.ARTIST_BEFORE_UPDATE)
        }
    }
}

/**
 * Create vue instance
 * @type {Vue}
 */
const artistsComponent = new Vue(artistsConfiguration)

/**
 * Module export
 */
export default artistsComponent

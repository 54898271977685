/**
 * @file events-list.js
 * @description Events utils
 */

/**
 * Component configuration
 * @type {Object}
 */
let eventsConfiguration = {
    /**
     * Component data
     * @return {Object}
     */
    data() {
        const data = {
            categories: [],
            selectedCategories: []
        }

        return data
    },

    /**
     * Watchers
     * @type {Object}
     */
    watch: {
        /**
         * Selected categories
         * @param selected
         * @return {Void}
         */
        selectedCategories(selected) {
            if (selected.length === this.categories.length) {
                this.resetSelectedCategories()
            }
        }
    },

    /**
     * Component methods
     * @return {Object}
     */
    methods: {
        /**
         * Reset selected categories
         * @return {Void}
         */
        resetSelectedCategories() {
            this.selectedCategories.splice(0, this.selectedCategories.length)
        },

        /**
         * Find in selected categories
         * @param category
         * @returns {boolean}
         */
        selected(category) {
            return _.find(this.selectedCategories, ['name', category])
        },

        /**
         * Toggle selected category
         * @param  {String} category
         * @return {Void}
         */
        toggleSelectedCategory(category) {
            if (this.selected(category)) {
                // Drop selected
                this.dropSelectedCategory(category)
            } else {
                // Append selected
                this.addSelectedCategory(category)
            }
        },

        /**
         * Add selected category
         * @param {String} category
         */
        addSelectedCategory(category) {
            this.selectedCategories.push({ name: category })
        },

        /**
         * Drop selected category
         * @param  {String} category
         * @return {Void}
         */
        dropSelectedCategory(category) {
            this.selectedCategories = _.reject(this.selectedCategories, ['name', category])
        },

        /**
         * Add categories
         * @param {string}
         * @return {Void}
         */
        addCategory(category) {
            if (!_.find(this.categories, ['name', category])) {
                this.categories.push({ name: category })
            }
        }
    }
}

/**
 * Create vue instance
 * @type {Vue}
 */
const eventsComponent = new Vue(eventsConfiguration)

/**
 * Module export
 */
export default eventsComponent

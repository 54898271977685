import * as PIXI from 'pixi.js'
import RectPattern from './bg-pattern-rect'
import * as PATTERN_CONF from './bg-pattern-conf'

export default class extends PIXI.Container {
    constructor() {
        super()

        this.counter = 0
        this.currentName = null
        this.currentPattern = null
    }

    addPattern(type, width, height, size) {
        // Increment counter
        this.counter += 1
        this.currentName = 'pattern' + this.counter

        // Create
        if (this.currentPattern != null) {
            this.currentPattern.destroy()
        }

        this.currentPattern = new RectPattern(this.getSquareBaseConf(width, height, size), type)

        // Show
        this.addChild(this.currentPattern)
    }

    getSquareBaseConf(width, height, size) {
        return {
            width: width,
            height: height,
            gutterW: PATTERN_CONF.BASE_GUTTER_WIDTH * size
        }
    }

    redrawPattern(width, height, size) {
        if (this.currentPattern != null) {
            this.currentPattern.draw(this.getSquareBaseConf(width, height, size), false)
        }
    }

    onBeforeResizeWindow() {
        this.currentPattern.startTicker()
    }

    onResizeWindow(rectInfo) {
        this.currentPattern.onResize(rectInfo)
    }
}

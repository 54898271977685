import { render, staticRenderFns } from "./visual-guide.vue?vue&type=template&id=25a1f600&"
import script from "./visual-guide.js?vue&type=script&lang=js&"
export * from "./visual-guide.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/www.chatelet.com/release/web/app/themes/theatre-du-chatelet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25a1f600')) {
      api.createRecord('25a1f600', component.options)
    } else {
      api.reload('25a1f600', component.options)
    }
    module.hot.accept("./visual-guide.vue?vue&type=template&id=25a1f600&", function () {
      api.rerender('25a1f600', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/scripts/tools/visual-guide/visual-guide.vue"
export default component.exports
/**
 * @file hero-slider.js
 * @description Hero Slider
 */

import { TimelineMax, TweenMax, Power4 } from 'gsap'
import { EVENTS } from '~/config/constants'
import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'
import ChateletBgPattern from '~/components/chatelet-bg-pattern/chatelet-bg-pattern.vue'
import * as ChateletBgPatternConf from '~/components/chatelet-bg-pattern/bg-pattern-api-conf'

let heroSliderConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'hero-slider',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    components: { ChateletBgPattern },

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperElement: '.slider-content',
    swiperPagination: '.navigations',
    swiperPaginationType: 'button',
    swiperPaginationConfig: {
        bulletElement: 'button',
        bulletClass: 'nav',
        bulletActiveClass: 'active',
        clickable: true
    },
    swiperConfig: {
        wrapperClass: 'slider-wrapper',
        slideClass: 'slider-item',
        setWrapperSize: false,
        slidesPerView: 1,
        virtualTranslate: true,
        autoplay: false
    },

    /**
     * Component data
     * @return {Object}
     */
    data() {
        const data = {
            active: 0,
            ready: false,
            animating: false,
            patternSize: ChateletBgPatternConf.TYPE_SIZE.NORMAL
        }

        return data
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        /**
         * Background color
         * @return {String}
         */
        background() {
            let background = '#111111'
            const active = $('.slider-item', this.$el).eq(this.active)

            if (active && active.data('color')) {
                background = active.data('color')
            }

            return background
        },

        /**
         * Canvas figure
         * @return {Number}
         */
        figure() {
            let figure = ChateletBgPatternConf.TYPE_PATTERN.RECT
            const active = $('.slider-item', this.$el).eq(this.active)

            if (active && active.data('figure')) {
                figure = active.data('figure') === 'circle' ? ChateletBgPatternConf.TYPE_PATTERN.CIRCLE : ChateletBgPatternConf.TYPE_PATTERN.RECT
            }

            return figure
        },

        /**
         * Canvas pattern data
         * @return {Object}
         */
        bgConf() {
            return {
                bgColor: this.background,
                patternType: this.figure
            }
        },

        /**
         * Current slide has next slide
         * @return {Boolean}
         */
        hasNext() {
            return this.ready && !this.animating && this.carouselInstance ? this.active < this.carouselInstance.slides.length - 1 : false
        }
    },

    /**
     * Component watchers
     * @type {Object}
     */
    watch: {
        /**
         * Active data watcher
         * @param  {Number} incoming
         * @param  {Number} outcoming
         * @return {Void}
         */
        active(incoming, outcoming) {
            this.toggleSlides(incoming, outcoming)
        }
    },

    /**
     * Component created handler
     * @return {Void}
     */
    created() {
        this.autoplay = false
        this.updatePos()

        this.$event.$on(
            EVENTS.WINDOW_RESIZE_IMMEDIATE,
            () => {
                this.updatePos()
                this.updateNextItem()
            }
        )
    },

    /**
     * Component mounted handler
     * @return {Void}
     */
    mounted() {
        this.attachCarouselEventHandler()
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Update positions
         * @return {Void}
         */
        updatePos() {
            this.fullPos = window.innerWidth
            this.extraPos = 280

            if (this.fullPos > 1023) {
                this.tierPos = this.fullPos / 3
                this.halfPos = this.fullPos / 1.6
            } else {
                this.tierPos = this.fullPos / 2
                this.halfPos = this.fullPos
            }
        },

        /**
         * Update next item
         * @return {Void}
         */
        updateNextItem() {
            const active = $('.slider-item', this.$el).eq(this.active + 1)

            if (active && active.length) {
                let nextpos = active.hasClass('slider-free') ? this.halfPos + this.extraPos : this.halfPos

                TweenMax.set(
                    $('.slider-item', this.$el).eq(this.active + 1).find('.slide-title'),
                    { x: nextpos }
                )
            }
        },

        /**
         * Attach carousel event handler
         * @return {Void}
         */
        attachCarouselEventHandler() {
            if (this.carouselInstance) {
                let auto = { current: 0 }
                let slides = this.carouselInstance.slides.length

                this.carouselInstance
                    .on(
                        'slideChange',
                        () => {
                            this.active = this.carouselInstance.activeIndex

                            if (this.autoplay) {
                                this.autoplay
                                    .kill()
                                    .restart(true)
                            }
                        }
                    )

                // Starting autoplay
                this.autoplay = TweenMax.to(
                    auto,
                    1,
                    {
                        delay: 10,
                        current: 1,
                        onCompleteScope: this,
                        onComplete() {
                            let target = this.active + 1

                            if (target === slides) {
                                target = 0
                            }

                            this.carouselInstance.slideTo(target)
                        }
                    }
                )
            }
        },

        /**
         * Carousel instance initialized override
         * @return {Void}
         */
        carouselInstanceInitialize() {
            let nextpos = this.halfPos
            let nextElement = false
            let elementsArray = []
            let loadTimeline = new TimelineMax(
                {
                    paused: true,
                    onStartScope: this,
                    onCompleteScope: this,
                    onStart() {
                        this.$el.classList.add('initialized')
                        this.carouselInstance.allowTouchMove = false
                    },
                    onComplete() {
                        this.ready = true
                        this.carouselInstance.allowTouchMove = true
                    }
                }
            )

            elementsArray.push($('.slider-item', this.$el).eq(0).find('.slide-infos'))
            elementsArray.push($('.slider-item', this.$el).eq(0).find('.slide-title'))
            elementsArray.push($('.slider-item', this.$el).eq(0).find('.slide-buttons'))
            elementsArray.push($('.slider-item', this.$el).eq(0).find('.slide-excerpt'))

            nextElement = $('.slider-item', this.$el).eq(1)

            if (nextElement && nextElement.length && nextElement.hasClass('slider-free')) {
                nextpos = this.halfPos + this.extraPos
            }

            loadTimeline
                .fromTo(
                    this.$el.querySelector('.hero-logo'),
                    2,
                    {
                        yPercent: 100,
                        autoAlpha: 0
                    },
                    {
                        delay: 0.25,
                        yPercent: 0,
                        autoAlpha: 1,
                        ease: Power4.easeOut,
                        immediateRender: true,
                        clearProps: 'all',
                        onCompleteScope: this,
                        onComplete() {
                            this.$el.querySelector('.hero-logo').classList.add('ready')
                        }
                    }
                )
                .fromTo(
                    this.$el.querySelector('.background'),
                    2,
                    {
                        yPercent: 100,
                        autoAlpha: 0
                    },
                    {
                        yPercent: 0,
                        autoAlpha: 1,
                        ease: Power4.easeOut,
                        immediateRender: true
                    },
                    '-=1.8'
                )
                .staggerFromTo(
                    elementsArray,
                    2,
                    {
                        y: 200,
                        autoAlpha: 0
                    },
                    {
                        y: 0,
                        autoAlpha: 1,
                        ease: Power4.easeOut,
                        immediateRender: true
                    },
                    0.1,
                    '-=1.4'
                )
                .fromTo(
                    $('.slider-item', this.$el).eq(0).find('.slide-image-inner'),
                    1.6,
                    { xPercent: 100 },
                    {
                        xPercent: 0,
                        ease: Power4.easeOut,
                        onStart() {
                            TweenMax.set(
                                $('.slider-item', this.$el).eq(0).find('.slide-image'),
                                { autoAlpha: 1 }
                            )
                        }
                    },
                    '-=2'
                )
                .fromTo(
                    $('.slider-item', this.$el).eq(0).find('.slide-image').find('.thumb'),
                    1.6,
                    {
                        xPercent: -100,
                        scale: 1.2
                    },
                    {
                        xPercent: 0,
                        scale: 1,
                        ease: Power4.easeOut
                    },
                    '-=2'
                )
                .fromTo(
                    $('.slider-item', this.$el).eq(1).find('.slide-title'),
                    1,
                    {
                        x: this.fullPos,
                        autoAlpha: 0
                    },
                    {
                        x: nextpos,
                        autoAlpha: 0.3,
                        immediateRender: true,
                        ease: Power4.easeOut
                    },
                    '-=2'
                )
                .play()
        },

        /**
         * Toggle slides
         * @param  {Number} incoming
         * @param  {Number} outcoming
         * @return {Void}
         */
        toggleSlides(incoming, outcoming) {
            let nextpos = this.halfPos
            let nextElement = false
            let elementsArray = []
            let nextElementsArray = []
            let direction = incoming > outcoming ? 1 : -1
            let toggleTimeline = new TimelineMax(
                {
                    paused: true,
                    onStartScope: this,
                    onCompleteScope: this,
                    onStart() {
                        this.animating = true
                        this.carouselInstance.allowSlideNext = false
                        this.carouselInstance.allowSlidePrev = false
                    },
                    onComplete() {
                        this.animating = false
                        this.carouselInstance.allowSlideNext = true
                        this.carouselInstance.allowSlidePrev = true
                    }
                }
            )

            // Updating layer index
            $('.slider-item', this.$el).eq(outcoming).find('.slide-image').css({ zIndex: 1 })
            $('.slider-item', this.$el).eq(incoming).find('.slide-image').css({ zIndex: 2 })

            // Group current element
            elementsArray.push(
                $('.slider-item', this.$el).eq(outcoming).find('.slide-infos'),
                $('.slider-item', this.$el).eq(outcoming).find('.slide-buttons'),
                $('.slider-item', this.$el).eq(outcoming).find('.slide-excerpt')
            )

            // Group incoming element
            nextElementsArray.push(
                $('.slider-item', this.$el).eq(incoming).find('.slide-infos'),
                $('.slider-item', this.$el).eq(incoming).find('.slide-buttons'),
                $('.slider-item', this.$el).eq(incoming).find('.slide-excerpt')
            )

            // Set timeline start
            toggleTimeline.addLabel('start')

            if (direction === 1) {
                /**
                 * Move forward
                 */

                // Push current title to current element group
                elementsArray.push($('.slider-item', this.$el).eq(outcoming).find('.slide-title'))

                if (incoming !== outcoming + 1) {
                    // Out of scope title
                    toggleTimeline.to(
                        $('.slider-item', this.$el).eq(outcoming + 1).find('.slide-title'),
                        1,
                        {
                            x: this.tierPos,
                            autoAlpha: 0,
                            ease: Power4.easeOut
                        },
                        'start'
                    )
                }
            } else {
                nextElement = outcoming !== incoming + 1 ? $('.slider-item', this.$el).eq(incoming + 1) : $('.slider-item', this.$el).eq(outcoming)

                if (nextElement && nextElement.length && nextElement.hasClass('slider-free')) {
                    nextpos = this.halfPos + this.extraPos
                }

                /**
                 * Move backward
                 */
                if (outcoming !== incoming + 1) {
                    elementsArray.push($('.slider-item', this.$el).eq(outcoming).find('.slide-title'))

                    toggleTimeline.fromTo(
                        $('.slider-item', this.$el).eq(incoming + 1).find('.slide-title'),
                        2,
                        {
                            x: this.tierPos,
                            autoAlpha: 0
                        },
                        {
                            x: nextpos,
                            autoAlpha: 0.3,
                            ease: Power4.easeOut
                        },
                        'start'
                    )

                    toggleTimeline.to(
                        $('.slider-item', this.$el).eq(outcoming + 1).find('.slide-title'),
                        1,
                        {
                            x: this.fullPos,
                            autoAlpha: 0,
                            ease: Power4.easeOut
                        },
                        'start'
                    )
                } else {
                    toggleTimeline.to(
                        $('.slider-item', this.$el).eq(outcoming + 1).find('.slide-title'),
                        1,
                        {
                            x: this.fullPos,
                            autoAlpha: 0,
                            ease: Power4.easeOut
                        },
                        'start'
                    )

                    toggleTimeline.to(
                        $('.slider-item', this.$el).eq(outcoming).find('.slide-title'),
                        2,
                        {
                            x: nextpos,
                            autoAlpha: 0.3,
                            ease: Power4.easeOut
                        },
                        'start'
                    )
                }
            }

            // Outcoming global elements
            toggleTimeline
                .to(
                    elementsArray,
                    1.6,
                    {
                        x: direction * -600,
                        autoAlpha: 0,
                        ease: Power4.easeOut
                    },
                    'start'
                )

            // Outcoming image element
            toggleTimeline
                .to(
                    $('.slider-item', this.$el).eq(outcoming).find('.slide-image-inner'),
                    1.8,
                    {
                        xPercent: direction * -50,
                        ease: Power4.easeOut,
                        onComplete() {
                            TweenMax.set(
                                this.target,
                                { xPercent: direction * -100 }
                            )
                        }
                    },
                    'start'
                )
                .to(
                    $('.slider-item', this.$el).eq(outcoming).find('.slide-image').find('.thumb'),
                    1.8,
                    {
                        xPercent: direction * 40,
                        ease: Power4.easeOut
                    },
                    'start'
                )

            // Incoming image element
            toggleTimeline
                .fromTo(
                    $('.slider-item', this.$el).eq(incoming).find('.slide-image-inner'),
                    1.6,
                    { xPercent: direction * 100 },
                    {
                        xPercent: 0,
                        ease: Power4.easeOut,
                        onStart() {
                            TweenMax.set(
                                $('.slider-item', this.$el).eq(incoming).find('.slide-image'),
                                { autoAlpha: 1 }
                            )
                        }
                    },
                    'start'
                )
                .fromTo(
                    $('.slider-item', this.$el).eq(incoming).find('.slide-image').find('.thumb'),
                    1.6,
                    {
                        xPercent: direction * -100,
                        scale: 1.2
                    },
                    {
                        xPercent: 0,
                        scale: 1,
                        ease: Power4.easeOut
                    },
                    'start'
                )

            if (direction === 1) {
                /**
                 * Move forward
                 */

                if (incoming !== outcoming + 1) {
                    // Out of scope incoming title
                    toggleTimeline.fromTo(
                        $('.slider-item', this.$el).eq(incoming).find('.slide-title'),
                        1.6,
                        {
                            x: this.fullPos,
                            autoAlpha: 0
                        },
                        {
                            x: 0,
                            autoAlpha: 1,
                            ease: Power4.easeOut
                        },
                        'start'
                    )
                } else {
                    // In scope incoming title
                    toggleTimeline.to(
                        $('.slider-item', this.$el).eq(incoming).find('.slide-title'),
                        1.6,
                        {
                            x: 0,
                            autoAlpha: 1,
                            ease: Power4.easeOut
                        },
                        'start'
                    )
                }
            } else {
                toggleTimeline.fromTo(
                    $('.slider-item', this.$el).eq(incoming).find('.slide-title'),
                    1.6,
                    {
                        x: -600,
                        autoAlpha: 0
                    },
                    {
                        x: 0,
                        autoAlpha: 1,
                        ease: Power4.easeOut
                    },
                    'start'
                )
            }

            // incoming group elements
            toggleTimeline
                .staggerFromTo(
                    nextElementsArray,
                    1.6,
                    {
                        x: direction * 600,
                        autoAlpha: 0
                    },
                    {
                        x: 0,
                        autoAlpha: 1,
                        ease: Power4.easeOut
                    },
                    0.1,
                    '-=1.6'
                )

            if (direction === 1) {
                /**
                 * Move forward
                 */
                nextElement = $('.slider-item', this.$el).eq(incoming + 1)

                if (nextElement && nextElement.length && nextElement.hasClass('slider-free')) {
                    nextpos = this.halfPos + this.extraPos
                }

                // Set next
                toggleTimeline.fromTo(
                    $('.slider-item', this.$el).eq(incoming + 1).find('.slide-title'),
                    1.6,
                    {
                        x: this.fullPos,
                        autoAlpha: 0
                    },
                    {
                        x: nextpos,
                        autoAlpha: 0.3,
                        ease: Power4.easeOut
                    },
                    '-=1.6'
                )
            }

            // Play timeline
            toggleTimeline.play()
        },

        /**
         * Go to next slide
         * @return {Void}
         */
        next() {
            this.carouselInstance.slideNext()
        },

        /**
         * Next mouse enter
         * @return  {Void}
         */
        nextMouseEnter() {
            let nextSlideTitle = $('.slider-item', this.$el).eq(this.active + 1).find('.slide-title')

            if (nextSlideTitle.length) {
                TweenMax.to(
                    nextSlideTitle,
                    1,
                    {
                        autoAlpha: 1,
                        ease: Power4.easeOut
                    }
                )
            }
        },

        /**
         * Next mouse leave
         * @return  {Void}
         */
        nextMouseLeave() {
            let nextSlideTitle = $('.slider-item', this.$el).eq(this.active + 1).find('.slide-title')

            if (nextSlideTitle.length) {
                TweenMax.to(
                    nextSlideTitle,
                    1,
                    {
                        autoAlpha: 0.3,
                        ease: Power4.easeOut
                    }
                )
            }
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const HeroSlider = Vue.extend(heroSliderConfiguration)

/**
 * Module export
 */
export default HeroSlider

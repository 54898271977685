import * as PIXI from 'pixi.js'
import { TweenMax } from 'gsap'

export default class extends PIXI.Container {
    constructor() {
        super()

        this.counter = 0
        this.currentName = null
        this.currentBg = null
    }

    addBg(w, h, color) {
        // Increment counter
        this.counter += 1
        this.currentName = 'bg' + this.counter

        // Create bg
        this.currentBg = new PIXI.Graphics()
        this.currentBg.beginFill(color)
        this.currentBg.drawRect(0, 0, w, h)
        this.currentBg.endFill()
        this.currentBg.alpha = 0
        this.currentBg.name = this.currentName

        // Show
        this.addChild(this.currentBg)
        TweenMax.to(
            this.currentBg,
            1,
            {
                alpha: 1,
                onComplete: () => {
                    this.clear()
                }
            })
    }

    clear() {
        for (const child of this.children) {
            if (child.name !== this.currentName) {
                child.destroy()
            }
        }
    }

    onResize(rectInfo) {
        this.currentBg.width = rectInfo.width
        this.currentBg.height = rectInfo.height
    }
}

/**
 * @file event-dates.js
 * @description Event dates
 */

import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'

/**
 * Event dates carousel configuration
 * @type {Object}
 */
let eventDatesConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'event-dates',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        start: {
            type: Number,
            default: 0
        }
    },

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperElement: '.event-dates-carousel',
    swiperConfig: {
        wrapperClass: 'event-dates-wrapper',
        slideClass: 'event-date',
        slidesPerView: 5,
        breakpoints: {
            1439: { slidesPerView: 4 },
            1119: { slidesPerView: 3 },
            719: { slidesPerView: 2 },
            539: { slidesPerView: 1.5 },
            360: { slidesPerView: 1 }
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const EventDates = Vue.extend(eventDatesConfiguration)

/**
 * Module export
 */
export default EventDates

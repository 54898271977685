/**
 * @file events-filter.js
 * @description Events list filter
 */

import axios from 'axios'
import { TweenMax, Power4 } from 'gsap'
import CarouselMixin from '~/mixins/carousel'
import BasicMixin from '~/mixins/basic'
import HomeFilterCarousel from '~/components/home-filter-carousel.vue'

/**
 * Component configuration
 * @type {Object}
 */
let homeEventFilterConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'home-filter',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin,
        HomeFilterCarousel
    ],

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperElement: '.search-list-filter-carousel',
    swiperConfig: {
        wrapperClass: 'search-list-filter-wrapper',
        slideClass: 'search-list-filter-item',
        slidesPerView: 'auto'
    },

    /**
     * Component props
     * @type {Object}
     */
    props: {
        title: {
            type: String,
            default: 'Recherche rapide'
        },
        filters: {
            type: Object,
            default: {}
        },
        ajaxurl: {
            type: String,
            default: 'ajax.php'
        },
        noresultwithsuggestions: {
            type: String,
            default: 'Aucun résultat, vous pourriez aimer...'
        },
        noresults: {
            type: String,
            default: 'Aucun résultat'
        }
    },

    /**
     * Component data
     * @return {Object}
     */
    data() {
        const data = {
            active: 0,
            request: {
                filters: {},
                results: {
                    haveResults: false,
                    isdefault: true,
                    isSuggestion: false,
                    datas: []
                }
            },
            activeFilter: 'categories',
            countActiveFilter: 0,
            loading: false
        }

        return data
    },

    /**
     * Component data watcher
     * @type {Object}
     */
    watch: {
        /**
         * Request result datas
         * @type {Object}
         */
        'request.results.datas': {
            immediate: true,
            handler() {
                if (!this.request.results.isdefault) {
                    this.listUpdateDone()
                }
            }
        }
    },

    /**
     * Created event handler
     * @return {Void}
     */
    created() {
        this.request.filters = this.filters
        this.filterVisible = 1
        this.cancelToken = axios.CancelToken
        this.homeAgendaRequest = false
        this.hideTransition = false
    },

    methods: {

        /**
         * Toggle filter item
         * @param  {String} parent
         * @param  {Number} self
         * @return {Void}
         */
        toggleFilterItem(parent, self) {
            // Toggle filter
            this.request.filters[parent].content[self].active = !this.request.filters[parent].content[self].active
            this.request.results.isSuggestion = false

            if (this.hideTransition) {
                /**
                 * Transition active
                 * Perform change process
                 */
                this.performFilterChange(parent, self)
            } else {
                /**
                 * Transitioning previous elements
                 */
                const currentEntries = this.$refs.list.querySelectorAll('.event-item')
                const topElements = this.$refs.list.querySelectorAll('.top-element')

                this.hideTransition = true

                if (topElements && topElements.length) {
                    TweenMax.to(
                        topElements,
                        0.3,
                        {
                            autoAlpha: 0,
                            ease: Power4.easeOut
                        }
                    )
                }

                if (currentEntries && currentEntries.length) {
                    TweenMax.staggerFromTo(
                        currentEntries,
                        0.5,
                        {
                            x: 0,
                            autoAlpha: 1
                        },
                        {
                            x: -200,
                            autoAlpha: 0,
                            ease: Power4.easeInOut
                        },
                        0.1,
                        this.unloadPreviousEnd,
                        [ parent, self ],
                        this
                    )
                } else {
                    this.unloadPreviousEnd(parent, self)
                }
            }
        },

        /**
         * Unload previous results end
         * @param  {String} parent
         * @param  {Number} self
         * @return {Void}
         */
        unloadPreviousEnd(parent, self) {
            TweenMax.set(
                this.$refs.list,
                {
                    autoAlpha: 0,
                    ease: Power4.easeOut,
                    onCompleteScope: this,
                    onComplete() {
                        // Transition end
                        this.hideTransition = false

                        // Cleanup result
                        // this.request.results.datas = [];

                        // Perform change process
                        this.performFilterChange(parent, self)
                    }
                }
            )
        },

        /**
         * Perform filter change
         * @param  {String} parent
         * @param  {Number} self
         * @return {Void}
         */
        performFilterChange(parent, self) {
            let needUpdate = false

            if (this.toggleHasActiveItem(this.request.filters[parent].content[self].active) === true) {
                needUpdate = true
            } else {
                // Abort existing request
                if (this.homeAgendaRequest) {
                    this.homeAgendaRequest.cancel('Agenda update aborted')
                }
            }

            this.loading = needUpdate

            if (needUpdate) {
                this.doRequest()
            }
        },

        /**
         * Toggle filter item
         * @param  {Boolean} status
         * @return {Boolean}
         */
        toggleHasActiveItem(status) {
            let hasActiveitem = true

            if (status === true) {
                this.countActiveFilter = this.countActiveFilter + 1
            } else {
                this.countActiveFilter = this.countActiveFilter - 1
            }

            if (this.countActiveFilter === 0) {
                this.request.results = {
                    haveResults: false,
                    isdefault: true,
                    isSuggestion: false,
                    datas: []
                }

                hasActiveitem = false
            }

            return hasActiveitem
        },

        /**
         * Filter has selection
         * @param  {Number|Boolean} id
         * @return {Boolean}
         */
        filterHasSelection(id = false) {
            let filter = this.request.filters[id]
            let hasSelection = false

            hasSelection = _.filter(filter.content, { 'active': true }).length

            return this.activeFilter !== id && hasSelection
        },

        /**
         * Activate filter
         * @param  {Number} id
         * @return {Void}
         */
        activateFilter(id = false) {
            if (!this.filterVisible || id === this.activeFilter) {
                this.toggleFilter()
            }

            if (!this.filterContentVisible || id === this.activeFilter) {
                this.toggleFilterContent()
            }

            this.activeFilter = id || _.keys(this.request.filters)[0]
            this.activeDateInput = 0
        },

        /**
         * Toggle filter content
         * @return {Void}
         */
        toggleFilterContent() {
            if (this.filterContentVisible) {
                this.activeFilter = false
            }

            this.filterContentVisible = !this.filterContentVisible
            this.activeDateInput = 0
        },

        /**
         * Toggle filter main list
         * @return {Void}
         */
        toggleFilter() {
            this.filterVisible = !this.filterVisible
        },

        /**
         * List update aborted
         * @param {Error} error
         * @return {[type]}
         */
        listUpdateAborted(reason) {
            if (reason !== 'Agenda update aborted') {
                this.request.results.datas = []
                this.loading = false
            }
        },

        /**
         * List update done
         * @return {Void}
         */
        listUpdateDone() {
            TweenMax.set(
                this.$refs.list,
                {
                    delay: 0.5,
                    autoAlpha: 1,
                    onCompleteScope: this,
                    onComplete() {
                        const currentEntries = this.$refs.list.querySelectorAll('.event-item')
                        const topElements = this.$refs.list.querySelectorAll('.top-element')

                        if (topElements && topElements.length) {
                            TweenMax.to(
                                topElements,
                                0.8,
                                {
                                    delay: 0.2,
                                    autoAlpha: 1,
                                    ease: Power4.easeOut
                                }
                            )
                        }

                        if (currentEntries && currentEntries.length) {
                            TweenMax.staggerFromTo(
                                currentEntries,
                                1,
                                {
                                    x: 200,
                                    autoAlpha: 0
                                },
                                {
                                    x: 0,
                                    autoAlpha: 1,
                                    clearProps: 'transform, opacity, visibility',
                                    ease: Power4.easeOut
                                },
                                0.16
                            )
                        }
                    }
                }
            )
        },

        /**
         * Perform request
         * @return {Void}
         */
        doRequest() {
            let params = new FormData()
            let requestparams = _.cloneDeep(this.request)

            params.append('data', JSON.stringify(requestparams))
            params.append('action', 'post_mini_agenda')

            if (this.homeAgendaRequest) {
                this.homeAgendaRequest.cancel('Agenda update aborted')
            }

            this.homeAgendaRequest = this.cancelToken.source()

            axios
                .post(
                    this.ajaxurl,
                    params,
                    { cancelToken: this.homeAgendaRequest.token }
                )
                .then(
                    response => {
                        if (response && response.data && response.status === 200) {
                            this.loading = false
                            this.request.results = response.data.results
                        }
                    }
                )
                .catch(
                    error => {
                        /**
                         * @todo  error handler
                         */
                        this.listUpdateAborted(error.message)
                    }
                )
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const HomeFilter = Vue.extend(homeEventFilterConfiguration)

/**
 * Module export
 */
export default HomeFilter

/**
 * @file visual-guide.js
 * @description Visual guide
 */

import store from '~/store/main'

let visualGuideConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'visual-guide',

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        column: () => _.range(12),
        row: () => _.range(100),
        vertical: () => store.state.guide.vertical,
        horizontal: () => store.state.guide.horizontal
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Toggle visibility
         * @param  {Number} type
         * @return {Void}
         */
        toggle(type) {
            switch (type) {
                case 1:
                    store.dispatch('toggleGuide', { vertical: !this.vertical })
                    break

                case 2:
                    store.dispatch('toggleGuide', { horizontal: !this.horizontal })
                    break
            }
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const VisualGuide = Vue.extend(visualGuideConfiguration)

/**
 * Module export
 */
export default VisualGuide

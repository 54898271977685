var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-result-list" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isdefault === false && _vm.haveResults === false,
              expression: "isdefault === false && haveResults === false"
            }
          ],
          staticClass: "suggestion top-element"
        },
        [_c("p", [_vm._v(_vm._s(_vm.message))])]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row row--items-middle" }, [
        _c(
          "div",
          { staticClass: "col-2 col-lg-1 col-offset-9 col-offset-lg-10" },
          [
            _c("div", { staticClass: "progression-col top-element" }, [
              _c("div", { staticClass: "progression-wrapper" }, [
                _c("div", { staticClass: "progress-navigation" }, [
                  _c("button", { staticClass: "prev" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          viewBox: "0 0 32 32",
                          width: "32",
                          height: "32"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d:
                              "M11 10.857L13.767 8 22 16.5 13.767 25 11 22.143l5.465-5.643z"
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "next" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          viewBox: "0 0 32 32",
                          width: "32",
                          height: "32"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d:
                              "M11 10.857L13.767 8 22 16.5 13.767 25 11 22.143l5.465-5.643z"
                          }
                        })
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "progression" })
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.events.length,
              expression: "events.length"
            }
          ],
          staticClass: "row row--items-center"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "events-list events-classic events-avantages events-carousel"
            },
            [
              _c(
                "div",
                { staticClass: "events-wrapper" },
                _vm._l(_vm.events, function(result, resultIndex) {
                  return _c(
                    "div",
                    { key: resultIndex, staticClass: "event-item" },
                    [
                      _c("article", { staticClass: "wrapper" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: result.url,
                              "aria-label": result.title
                            }
                          },
                          [
                            _c("div", { staticClass: "top" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "extra",
                                  attrs: { "aria-hidden": "true" }
                                },
                                [
                                  _c(
                                    "svg",
                                    { attrs: { viewBox: "0 0 32 32" } },
                                    [
                                      _c("path", {
                                        attrs: {
                                          fill: "#111",
                                          d:
                                            "M13.612 22l2.372-2.131L18.488 22H20V10h-1.512l-2.504 2.12L13.612 10H12v12h1.612zm4.144-14H22v16h-4.248l-1.732-1.474L14.379 24H10V8h4.376l1.644 1.469L17.756 8zM12 16h8v2h-8v-2z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "thumb" }, [
                                _c("figure", [
                                  result.media
                                    ? _c("img", {
                                        staticClass: "lazyload",
                                        attrs: {
                                          "data-width": result.media.src[1],
                                          "data-height": result.media.src[2],
                                          "data-type": result.media.type,
                                          "data-srcset": result.media.srcset,
                                          "data-src": result.media.src[0],
                                          sizes: result.media.sizes,
                                          alt: ""
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "content" }, [
                              _c("header", { staticClass: "header-event" }, [
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: result.date,
                                        expression: "result.date"
                                      }
                                    ],
                                    staticClass: "date"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class:
                                          result.date.day.length > 1
                                            ? "day multiple"
                                            : "day"
                                      },
                                      _vm._l(result.date.day, function(
                                        singledate
                                      ) {
                                        return _c("span", [
                                          _vm._v(_vm._s(singledate))
                                        ])
                                      }),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        class:
                                          result.date.month.length > 1
                                            ? "month multiple"
                                            : "month"
                                      },
                                      _vm._l(result.date.month, function(
                                        singlemonth
                                      ) {
                                        return _c("span", [
                                          _vm._v(_vm._s(singlemonth))
                                        ])
                                      }),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        class:
                                          result.date.year.length > 1
                                            ? "year multiple"
                                            : "year"
                                      },
                                      _vm._l(result.date.year, function(
                                        singleyear
                                      ) {
                                        return _c("span", [
                                          _vm._v(_vm._s(singleyear))
                                        ])
                                      }),
                                      0
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "infos" }, [
                                  _c("h3", {
                                    domProps: {
                                      innerHTML: _vm._s(result.title)
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("ul", { staticClass: "infos-list" }, [
                                result.type
                                  ? _c(
                                      "li",
                                      {
                                        staticClass: "type",
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.doAction(result.cat_url)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(result.type))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                result.date.hour
                                  ? _c("li", [_vm._v(_vm._s(result.date.hour))])
                                  : _vm._e(),
                                _vm._v(" "),
                                result.place
                                  ? _c("li", { staticClass: "place" }, [
                                      _vm._v(_vm._s(result.place))
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
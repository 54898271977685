var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "events-list-filter" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "ul",
        { staticClass: "events-filter" },
        [
          _vm.categories.length > 1
            ? _c("li", [
                _c(
                  "button",
                  {
                    class: { active: !_vm.selectedCategories.length },
                    attrs: { "aria-hidden": "true" },
                    on: {
                      click: function($event) {
                        return _vm.toggleSelected()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.tous))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.categories, function(category) {
            return _vm.categories.length > 1
              ? _c("li", [
                  _c(
                    "button",
                    {
                      class: { active: _vm.selected(category.name) },
                      attrs: { "aria-hidden": "true" },
                      on: {
                        click: function($event) {
                          return _vm.toggleSelected(category.name)
                        }
                      }
                    },
                    [_vm._v(_vm._s(category.name))]
                  )
                ])
              : _vm._e()
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "artistes-list" }, [
    _c("div", { staticClass: "artistes-list-trigger" }),
    _vm._v(" "),
    _c("div", { staticClass: "artistes-list-anchor" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row row--items-center" }, [
          _c("div", { staticClass: "anchor-carousel" }, [
            _c(
              "div",
              { staticClass: "anchor-wrapper" },
              [
                _vm._l(_vm.anchors, function(anchor) {
                  return _c("div", { staticClass: "anchor-item" }, [
                    _c(
                      "a",
                      {
                        class: {
                          enabled: _vm.activeSlug.indexOf(anchor) !== -1
                        },
                        attrs: { href: "#", "aria-label": anchor },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.goto(anchor)
                          }
                        }
                      },
                      [_vm._v(_vm._s(anchor))]
                    )
                  ])
                }),
                _vm._v(" "),
                _c("div", { staticClass: "anchor-indicator" })
              ],
              2
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { ref: "list", staticClass: "artistes-list-elements" },
      _vm._l(_vm.filteredArtistsData, function(artistData) {
        return _c(
          "div",
          {
            key: artistData.slug,
            staticClass: "artistes-list-block",
            attrs: { id: ["artiste-" + artistData.slug] }
          },
          [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row nomargin" }, [
                _c("div", { staticClass: "col-2 col-offset-1 title-col" }, [
                  _c("h2", [_vm._v(_vm._s(artistData.slug))])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-8 nopad content-col" }, [
                  _c(
                    "ul",
                    _vm._l(artistData.list, function(artist, index) {
                      return _c("li", { key: index }, [
                        _c("div", [
                          _c("p", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: artist.url,
                                  "aria-label": artist.name
                                }
                              },
                              [_vm._v(_vm._s(artist.name))]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            _vm._l(artist.category, function(category, key) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.filter(category.id)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(category.name))]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("a", {
                            staticClass: "ext",
                            attrs: {
                              href: artist.url,
                              "aria-label": artist.name
                            }
                          })
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
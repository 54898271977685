var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-agenda" }, [
    _c("div", { staticClass: "main-agenda-wrapper" }, [
      _c("div", { staticClass: "container button-container nopad" }, [
        _c("div", { staticClass: "button-wrapper" }, [
          _c("button", {
            staticClass: "close",
            attrs: { "aria-label": "fermer" },
            on: { click: _vm.hide }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "agenda-top" }, [
        _c("div", { staticClass: "agenda-season-filter glob" }, [
          _c("div", { staticClass: "container nopad" }, [
            _vm.request.seasons
              ? _c(
                  "ul",
                  _vm._l(_vm.request.seasons, function(season, index) {
                    return _c("li", { key: season.id }, [
                      _c(
                        "a",
                        {
                          class: { active: season.selected },
                          attrs: { href: "#", "aria-label": season.label },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setActiveSeason(index)
                            }
                          }
                        },
                        [_vm._v(_vm._s(season.label))]
                      )
                    ])
                  }),
                  0
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "agenda-month-filter glob" }, [
          _c("div", { staticClass: "container nopad month-container" }, [
            _vm.activeSeason.months
              ? _c("div", { staticClass: "month-carousel" }, [
                  _c(
                    "ul",
                    { staticClass: "month-wrapper" },
                    _vm._l(_vm.activeSeason.months, function(month, index) {
                      return _c(
                        "li",
                        {
                          key:
                            "season-" + _vm.activeSeason.id + "-month-" + index,
                          staticClass: "month-item",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.goToMonth(index)
                            }
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(_vm._s(month.label) + " "),
                            _c("span", [_vm._v(_vm._s(month.year))])
                          ])
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("button", {
                    staticClass: "month-prev",
                    attrs: { "aria-label": "mois précédent" }
                  }),
                  _vm._v(" "),
                  _c("button", {
                    staticClass: "month-next",
                    attrs: { "aria-label": "mois suivant" }
                  })
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "agenda-toggle-filter glob" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-10 col-md-9 col-offset-1 col-offset-md-2" },
              [
                _c(
                  "button",
                  {
                    staticClass: "cta-btn cta-btn-dark-8",
                    attrs: { "aria-label": _vm.textfiltrer },
                    on: { click: _vm.toggleFilter }
                  },
                  [
                    _c("span", { staticClass: "bg" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.textfiltrer))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "cta-btn cta-btn-dark-8 tablet-only",
                    attrs: { "aria-label": _vm.textfiltrer },
                    on: {
                      click: function($event) {
                        return _vm.activateFilter(false)
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "bg" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.textfiltrer))
                    ])
                  ]
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "agenda-main-filter glob" }, [
        _c("div", { staticClass: "container nopad" }, [
          _c("div", { staticClass: "filter-list" }, [
            _c("div", { staticClass: "filter-wrapper" }, [
              _c("div", { staticClass: "filter-top" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-back",
                    attrs: { "aria-hidden": "true" },
                    on: { click: _vm.toggleFilter }
                  },
                  [
                    _c("svg", { attrs: { viewBox: "0 0 32 32" } }, [
                      _c("path", {
                        attrs: {
                          fill: "#111",
                          d:
                            "M9.939 7.061l2.122-2.122L23.121 16l-11.06 11.061-2.122-2.122L18.879 16z"
                        }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.textfiltrer))
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn-refresh",
                    class: { active: _vm.enableResetFilter },
                    attrs: { "aria-label": _vm.textfiltrer },
                    on: { click: _vm.resetFilter }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.textfiltrer))]),
                    _vm._v(" "),
                    _c("svg", { attrs: { viewBox: "0 0 25 25" } }, [
                      _c("path", {
                        attrs: {
                          fill: "#111",
                          "fill-rule": "evenodd",
                          d:
                            "M2.966 10a9.44 9.44 0 0 1 1.079-2.463 9.66 9.66 0 0 1 2.073-2.39 9.433 9.433 0 0 1 2.776-1.573A9.346 9.346 0 0 1 12.139 3a9.26 9.26 0 0 1 3.687.752 9.644 9.644 0 0 1 3.033 2.019 9.441 9.441 0 0 1 2.028 3.023c.502 1.171.752 2.4.752 3.686a9.29 9.29 0 0 1-.752 3.691 9.544 9.544 0 0 1-2.028 3.038 9.627 9.627 0 0 1-3.033 2.034 9.217 9.217 0 0 1-3.687.757c-2.177 0-4.126-.673-5.848-2.019l1.81-2.325c1.202.923 2.547 1.385 4.038 1.385a6.386 6.386 0 0 0 3.286-.88 6.511 6.511 0 0 0 2.38-2.39 6.44 6.44 0 0 0 .875-3.29c0-.885-.173-1.73-.519-2.54a6.585 6.585 0 0 0-1.395-2.087 6.585 6.585 0 0 0-2.088-1.395 6.378 6.378 0 0 0-2.539-.52c-1.444 0-2.74.427-3.884 1.282A6.475 6.475 0 0 0 6.084 10H9l-4.5 7L0 10h2.966z"
                        }
                      })
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-bottom" }, [
                _vm.request.filters
                  ? _c(
                      "ul",
                      _vm._l(_vm.request.filters, function(filter, index) {
                        return _c(
                          "li",
                          {
                            class: {
                              selected: _vm.activeFilter === index,
                              checked: _vm.filterHasSelection(index)
                            },
                            on: {
                              click: function($event) {
                                return _vm.activateFilter(index)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(filter.label) +
                                "\n                                        "
                            ),
                            _c("svg", { attrs: { viewBox: "0 0 32 32" } }, [
                              _c("path", {
                                attrs: {
                                  fill: "#111",
                                  d:
                                    "M15.79 20.18l7.934-12.855 2.552 1.576-10.143 16.436-7.324-8.951 2.321-1.9z"
                                }
                              })
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "cta-btn cta-btn-border-out cta-btn-dark",
                    attrs: { "aria-label": _vm.textvalider },
                    on: { click: _vm.validateFilterChange }
                  },
                  [
                    _c("span", { staticClass: "bg" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.textvalider))
                    ])
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-content" }, [
            _c("div", { staticClass: "filter-wrapper" }, [
              _c("div", { staticClass: "filter-top" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-back",
                    attrs: { "aria-hidden": "true" },
                    on: { click: _vm.toggleFilterContent }
                  },
                  [
                    _c("svg", { attrs: { viewBox: "0 0 32 32" } }, [
                      _c("path", {
                        attrs: {
                          fill: "#111",
                          d:
                            "M9.939 7.061l2.122-2.122L23.121 16l-11.06 11.061-2.122-2.122L18.879 16z"
                        }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.mobileNavigationLabel))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-bottom" }, [
                _vm.request.filters
                  ? _c(
                      "div",
                      { staticClass: "filter-content-item-wrapper" },
                      _vm._l(_vm.request.filters, function(filter, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.activeFilter === index,
                                expression: "activeFilter === index"
                              }
                            ],
                            key: filter.id,
                            staticClass: "filter-content-item"
                          },
                          [
                            filter.type === "list"
                              ? _c("div", { staticClass: "list" }, [
                                  _c(
                                    "ul",
                                    {
                                      class: {
                                        multiple: filter.content.length > 4
                                      }
                                    },
                                    _vm._l(filter.content, function(item, key) {
                                      return _c(
                                        "li",
                                        {
                                          key: item.id,
                                          class: { selected: item.active },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleFilterItem(
                                                index,
                                                key
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(item.label))]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "button" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "cta-btn cta-btn-icon btn-validate",
                                        attrs: {
                                          "aria-label": _vm.textvalider
                                        },
                                        on: { click: _vm.validateFilterChange }
                                      },
                                      [
                                        _c("span", { staticClass: "bg" }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v(_vm._s(_vm.textvalider))
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "cta-btn btn-cancel",
                                        attrs: {
                                          "aria-label": _vm.textannuler
                                        },
                                        on: { click: _vm.closeFilters }
                                      },
                                      [
                                        _c("span", { staticClass: "bg" }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v(_vm._s(_vm.textannuler))
                                        ])
                                      ]
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            filter.type === "date"
                              ? _c("div", { staticClass: "calendar" }, [
                                  _c(
                                    "div",
                                    { staticClass: "date-picker" },
                                    [
                                      _c("v-calendar", {
                                        attrs: {
                                          attributes: _vm.pickerAttributes,
                                          "theme-styles": _vm.pickerStyles,
                                          formats: _vm.pickerFormats,
                                          "min-date": _vm.minDate,
                                          "max-date": _vm.maxDate,
                                          "from-page": _vm.fromPage,
                                          "nav-visibility": "hidden"
                                        },
                                        on: { dayclick: _vm.updateDate }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "range-input" }, [
                                    _c("div", { staticClass: "range" }, [
                                      _c(
                                        "p",
                                        {
                                          class: {
                                            selected: _vm.activeDateInput === 0
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.selectDateInput(0)
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.textfrom))
                                          ]),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.startDate))
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          class: {
                                            selected: _vm.activeDateInput === 1
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.selectDateInput(1)
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.textto))
                                          ]),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.endDate))
                                          ])
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "button" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "cta-btn cta-btn-icon btn-validate",
                                          attrs: {
                                            "aria-label": _vm.textvalider
                                          },
                                          on: {
                                            click: _vm.validateFilterChange
                                          }
                                        },
                                        [
                                          _c("span", { staticClass: "bg" }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "label" }, [
                                            _vm._v(_vm._s(_vm.textvalider))
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "cta-btn btn-cancel",
                                          attrs: {
                                            "aria-label": _vm.textannuler
                                          },
                                          on: { click: _vm.closeFilters }
                                        },
                                        [
                                          _c("span", { staticClass: "bg" }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "label" }, [
                                            _vm._v(_vm._s(_vm.textannuler))
                                          ])
                                        ]
                                      )
                                    ])
                                  ])
                                ])
                              : _vm._e()
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "agenda-content-mask",
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.closeFilters($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "agenda-main-mask" }),
      _vm._v(" "),
      _c("div", { staticClass: "agenda-loader" }, [
        _c("div", { staticClass: "container nopad" }, [
          _c("div", { staticClass: "loader" }, [
            _c("div", { staticClass: "loader-content" }, [
              _c("svg", { attrs: { viewport: "0 0 20 20" } }, [
                _c("circle", { attrs: { cx: "10", cy: "10", r: "9" } })
              ]),
              _vm._v(" "),
              _c("span", [_vm._v("chargement")])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "agenda-content" }, [
        _c("div", { staticClass: "container nopad" }, [
          _vm.results
            ? _c(
                "div",
                { staticClass: "agenda-content-items" },
                [
                  !_vm.results.length
                    ? _c("p", { staticClass: "agenda-no-result" }, [
                        _vm._v(_vm._s(_vm.textnoresult))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.results, function(result, resultIndex) {
                    return _c(
                      "div",
                      {
                        key: "event-" + resultIndex,
                        staticClass: "agenda-item",
                        class: {
                          "same-date": _vm.isSameDate(resultIndex),
                          "is-past": result.ispast
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: result.url,
                              "aria-label": result.title
                            }
                          },
                          [
                            _c("div", { staticClass: "content-medias" }, [
                              _c("div", { staticClass: "media" }, [
                                _c(
                                  "div",
                                  { staticClass: "extra-wrapper" },
                                  [
                                    result.performances.length > 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "extra-hour",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                              }
                                            }
                                          },
                                          _vm._l(result.performances, function(
                                            performance,
                                            index
                                          ) {
                                            return _c(
                                              "span",
                                              {
                                                key: "extra-hour-" + index,
                                                class: {
                                                  selected:
                                                    performance.selected,
                                                  multiple: performance.multiple
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.toggleRepresentation(
                                                      resultIndex,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(performance.hour)
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                performance.multiple
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          performance.hour_end
                                                        )
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(result.performances, function(
                                      performance,
                                      index
                                    ) {
                                      return !result.ispast &&
                                        performance.status === "LIMITED"
                                        ? _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: performance.selected,
                                                  expression:
                                                    "performance.selected"
                                                }
                                              ],
                                              key: "status" + index,
                                              staticClass: "last-place"
                                            },
                                            [_vm._v(_vm._s(_vm.textlastplaces))]
                                          )
                                        : _vm._e()
                                    }),
                                    _vm._v(" "),
                                    _vm._l(result.performances, function(
                                      performance,
                                      index
                                    ) {
                                      return [
                                        !result.ispast &&
                                        performance.status === "NONE"
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: performance.selected,
                                                    expression:
                                                      "performance.selected"
                                                  }
                                                ],
                                                key: "resa" + index,
                                                staticClass: "extra full",
                                                attrs: {
                                                  "aria-label": _vm.textcomplet
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.textcomplet)
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !result.ispast &&
                                        performance.perf_id !== null &&
                                        performance.status !== "NONE"
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: performance.selected,
                                                    expression:
                                                      "performance.selected"
                                                  }
                                                ],
                                                key: "resa" + index,
                                                staticClass: "extra",
                                                attrs: {
                                                  "aria-label": _vm.textreserver
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.book(
                                                      performance.action
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      viewBox: "0 0 32 32"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        fill: "currentColor",
                                                        d:
                                                          "M13.612 22l2.372-2.131L18.488 22H20V10h-1.512l-2.504 2.12L13.612 10H12v12h1.612zm4.144-14H22v16h-4.248l-1.732-1.474L14.379 24H10V8h4.376l1.644 1.469L17.756 8zM12 16h8v2h-8v-2z"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "thumb" }, [
                                  _c("figure", [
                                    result.media
                                      ? _c("img", {
                                          staticClass: "lazyload",
                                          attrs: {
                                            "data-width": result.media.src[1],
                                            "data-height": result.media.src[2],
                                            "data-srcset": result.media.srcset,
                                            "data-src": result.media.src[0],
                                            sizes: result.media.sizes,
                                            alt: ""
                                          }
                                        })
                                      : _vm._e()
                                  ])
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "content-wrapper" }, [
                              _c("div", { staticClass: "content" }, [
                                _c("h2", [_vm._v(_vm._s(result.title))]),
                                _vm._v(" "),
                                _c("div", { staticClass: "infos" }, [
                                  _c("div", { staticClass: "date" }, [
                                    _vm._v(
                                      _vm._s(result.rawdates.day) +
                                        " " +
                                        _vm._s(result.rawdates.monthName) +
                                        " " +
                                        _vm._s("20" + result.rawdates.year)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "infos-inner" }, [
                                    result.type
                                      ? _c("div", { staticClass: "type" }, [
                                          _vm._v(_vm._s(result.type))
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      { staticClass: "event-infos" },
                                      [
                                        _vm._l(result.performances, function(
                                          performance,
                                          index
                                        ) {
                                          return _c(
                                            "li",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: performance.selected,
                                                  expression:
                                                    "performance.selected"
                                                }
                                              ],
                                              key: "hour" + index,
                                              staticClass: "hour"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(performance.hour))
                                              ])
                                            ]
                                          )
                                        }),
                                        _vm._v(" "),
                                        result.place
                                          ? _c("li", [
                                              _vm._v(_vm._s(result.place))
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        result.price && result.price !== "N-C"
                                          ? _c("li", [
                                              _vm._v(_vm._s(result.price))
                                            ])
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      { staticClass: "event-adh" },
                                      _vm._l(result.adh_type, function(
                                        adh,
                                        index
                                      ) {
                                        return _c(
                                          "li",
                                          { key: "adh" + index },
                                          [
                                            _vm._v(
                                              " " + _vm._s(adh.name) + " "
                                            ),
                                            _c("span", { style: adh.color })
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content-action" }, [
                                _c(
                                  "div",
                                  { staticClass: "action" },
                                  [
                                    result.performances.length > 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "extra-hour",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                              }
                                            }
                                          },
                                          _vm._l(result.performances, function(
                                            performance,
                                            index
                                          ) {
                                            return _c(
                                              "span",
                                              {
                                                key: "extra-hour-" + index,
                                                class: {
                                                  selected: performance.selected
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.toggleRepresentation(
                                                      resultIndex,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(performance.hour)
                                                  )
                                                ])
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(result.performances, function(
                                      performance,
                                      index
                                    ) {
                                      return [
                                        performance.is_show_link &&
                                        performance.link_out
                                          ? _c(
                                              "span",
                                              { key: "perf" + index },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          performance.selected,
                                                        expression:
                                                          "performance.selected"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "cta-btn cta-btn-book cta-btn-icon",
                                                    attrs: {
                                                      "aria-label":
                                                        _vm.textreserver
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.openWindow(
                                                          performance.link_out,
                                                          "_blank"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass: "bg"
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          viewBox: "0 0 32 32"
                                                        }
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            fill: "#111",
                                                            d:
                                                              "M13.612 22l2.372-2.131L18.488 22H20V10h-1.512l-2.504 2.12L13.612 10H12v12h1.612zm4.144-14H22v16h-4.248l-1.732-1.474L14.379 24H10V8h4.376l1.644 1.469L17.756 8zM12 16h8v2h-8v-2z"
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "label" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.textreserver
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                key: "perf-e" + index,
                                                staticStyle: {
                                                  "text-align": "right"
                                                }
                                              },
                                              [
                                                !result.ispast &&
                                                performance.is_secutix &&
                                                performance.perf_id !== null &&
                                                performance.status === "NONE"
                                                  ? _c(
                                                      "button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              performance.selected,
                                                            expression:
                                                              "performance.selected"
                                                          }
                                                        ],
                                                        key: "resa" + index,
                                                        staticClass:
                                                          "cta-btn cta-btn-icon",
                                                        attrs: {
                                                          "aria-label":
                                                            _vm.textlisteattente
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.book(
                                                              performance.action
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass: "bg"
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "svg",
                                                          {
                                                            attrs: {
                                                              viewBox:
                                                                "0 0 32 32"
                                                            }
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                fill: "#111",
                                                                d:
                                                                  "M13.612 22l2.372-2.131L18.488 22H20V10h-1.512l-2.504 2.12L13.612 10H12v12h1.612zm4.144-14H22v16h-4.248l-1.732-1.474L14.379 24H10V8h4.376l1.644 1.469L17.756 8zM12 16h8v2h-8v-2z"
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.textlisteattente
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : !result.ispast &&
                                                    !performance.is_secutix &&
                                                    performance.status ===
                                                      "NONE"
                                                  ? _c(
                                                      "button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              performance.selected,
                                                            expression:
                                                              "performance.selected"
                                                          }
                                                        ],
                                                        key: "resa" + index,
                                                        staticClass:
                                                          "cta-btn cta-full extra-style",
                                                        attrs: {
                                                          "aria-label":
                                                            _vm.textcomplet
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass: "bg"
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.textcomplet
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : !result.ispast &&
                                                    performance.perf_id !==
                                                      null &&
                                                    (performance.status ===
                                                      "GOOD" ||
                                                      performance.status ===
                                                        "LIMITED") &&
                                                    result.est_en_vente
                                                  ? _c(
                                                      "button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              performance.selected,
                                                            expression:
                                                              "performance.selected"
                                                          }
                                                        ],
                                                        key: "resa" + index,
                                                        staticClass:
                                                          "cta-btn cta-btn-book cta-btn-icon",
                                                        attrs: {
                                                          "aria-label":
                                                            _vm.textreserver
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.book(
                                                              performance.action
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass: "bg"
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "svg",
                                                          {
                                                            attrs: {
                                                              viewBox:
                                                                "0 0 32 32"
                                                            }
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                fill: "#111",
                                                                d:
                                                                  "M13.612 22l2.372-2.131L18.488 22H20V10h-1.512l-2.504 2.12L13.612 10H12v12h1.612zm4.144-14H22v16h-4.248l-1.732-1.474L14.379 24H10V8h4.376l1.644 1.469L17.756 8zM12 16h8v2h-8v-2z"
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.textreserver
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                      ]
                                    }),
                                    _vm._v(" "),
                                    _vm._l(result.performances, function(
                                      performance,
                                      index
                                    ) {
                                      return performance.subtitle_disponibility &&
                                        performance.is_show_subtitle
                                        ? _c(
                                            "p",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: performance.selected,
                                                  expression:
                                                    "performance.selected"
                                                }
                                              ],
                                              key: "avail" + index,
                                              staticClass: "last"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    performance.subtitle_disponibility
                                                  ) +
                                                  "\n                                            "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    }),
                                    _vm._v(" "),
                                    _vm._l(result.performances, function(
                                      performance,
                                      index
                                    ) {
                                      return _c(
                                        "p",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !performance.ispast &&
                                                !result.est_en_vente,
                                              expression:
                                                "!performance.ispast && !result.est_en_vente"
                                            }
                                          ],
                                          key: "vente" + index,
                                          staticClass: "last"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(result.date_debut_vente) +
                                              "\n                                            "
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _vm._l(result.performances, function(
                                      performance,
                                      index
                                    ) {
                                      return !result.ispast &&
                                        performance.status === "LIMITED"
                                        ? _c(
                                            "p",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: performance.selected,
                                                  expression:
                                                    "performance.selected"
                                                }
                                              ],
                                              key: "status" + index,
                                              staticClass: "last"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(_vm.textlastplaces) +
                                                  "\n                                            "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    })
                                  ],
                                  2
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
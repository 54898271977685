/**
 * @file medias-carousel.js
 * @description Artistes carousel
 */

// import { TweenMax, Back, Power4 } from 'gsap';
import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'

/**
 * Medias carousel configuration
 * @type {Object}
 */
let mediasCarouselConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'medias-carousel',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        start: {
            type: Number,
            default: 0
        }
    },

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperElement: '.medias-carousel',
    swiperConfig: {
        wrapperClass: 'medias-carousel-wrapper',
        slideClass: 'medias-carousel-item',
        slidesPerView: 1

        /*
        breakpoints: {
            1024: { slidesPerView: 2 },
            479: { slidesPerView: 1.25 }
        }
        */
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {},

    /**
     * Data watchers
     * @type {Object}
     */
    watch: {},

    /**
     * Component methods
     * @type {Object}
     */
    methods: {}
}

/**
 * Register component
 * @type {Vue}
 */
const MediasCarousel = Vue.extend(mediasCarouselConfiguration)

/**
 * Module export
 */
export default MediasCarousel

/**
 * @file basic.js
 * @description Basic mixin
 */

import eventsComponent from '~/utils/events'
import { EVENTS } from '../config/constants'

/**
 * Shared configuration
 * @type {Object}
 */
const basicMixin = {
    /**
     * Name config
     * @type {String}
     */
    name: String,

    /**
     * Created event handler
     * @return {Void}
     */
    created() {
        console.info('🔸 %s created', this.$options.name.toUpperCase())

        // Dispatch global event refs
        this.$event = eventsComponent
    },

    /**
     * Mounted event handler
     * @return {Void}
     */
    mounted() {
        console.info('🔹 %s mounted', this.$options.name.toUpperCase())
        this.$event.dispatch(EVENTS.COMPONENT_MOUNTED)
    },

    /**
     * Updated event handler
     * @return {Void}
     */
    updated() {
        console.info('🔹 %s updated', this.$options.name.toUpperCase())
    },

    /**
     * Destroyed event handler
     * @return {Void}
     */
    destroyed() {
        console.info('🔸 %s destroyed', this.$options.name.toUpperCase())
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Leading zero
         * @param  {Number|String} number
         * @return {String}
         */
        leadingZero(number) {
            return ('0' + number).slice(-2)
        }
    }
}

/**
 * Export configuration
 */
export default basicMixin

import * as PIXI from 'pixi.js'
import BgColorContainer from './bg-color-container'
import BgPatternContainer from './bg-pattern-container'

PIXI.utils.skipHello()

const bgPattern = {
    /**
     * Props
     * */
    props: {
        bgConf: Object,
        patternSize: Number
    },

    /**
     * Component name
     * @type {String}
     */
    name: 'chatelet-bg-pattern',

    /**
     * Non reactive data
     * */
    static: {
        bgApp: null,
        stage: null,
        renderer: null,

        canvasEl: null,
        bgWidth: 0,
        bgHeight: 0,
        bgClip: null,
        bgPatternContainer: null
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Init pixi app
         * @type {boolean}
         */
        initApp() {
            this.canvasEl = this.$el

            if (this.canvasEl && this.canvasEl.offsetWidth > 0 && this.canvasEl.offsetHeight > 0) {
                this.bgWidth = this.canvasEl.offsetWidth
                this.bgHeight = this.canvasEl.offsetHeight
                this.bgApp = new PIXI.Application(
                    this.bgWidth,
                    this.bgHeight,
                    {
                        autoStart: false,
                        view: this.canvasEl,
                        antialias: true,
                        transparent: true,
                        resolution: window.devicePixelRatio,
                        forceCanvas: false,
                        sharedTicker: true
                    }
                )
                this.stage = this.bgApp.stage
                this.renderer = this.bgApp.renderer

                return true
            }

            return false
        },

        // Init bg color
        initContainers() {
            // Bg color
            this.bgClip = new BgColorContainer()
            this.stage.addChild(this.bgClip)

            // Patterns container
            this.bgPatternContainer = new BgPatternContainer()
            this.stage.addChild(this.bgPatternContainer)
        },

        // Add background pattern
        addNewBg() {
            // Change bg
            this.bgClip && this.bgClip.addBg(this.bgWidth, this.bgHeight, parseInt(this.bgConf.bgColor.substring(1), 16))

            // pattern
            this.bgPatternContainer && this.bgPatternContainer.addPattern(
                this.bgConf.patternType,
                this.bgWidth,
                this.bgHeight,
                this.patternSize,
                this.renderer
            )
        },

        /**
         * Window resize handler
         * */
        onWindowResize() {
            const rect = this.canvasEl.getBoundingClientRect()

            this.bgPatternContainer.onBeforeResizeWindow()

            this.bgWidth = rect.width
            this.bgHeight = rect.height
            this.renderer.resize(rect.width, rect.height)
            this.bgClip.onResize(rect)
            this.bgPatternContainer.onResizeWindow(rect)
        }
    },

    /**
     * Watch for props/data changes
     * */
    watch: {
        bgConf: {
            handler() {
                this.addNewBg()
            },
            deep: true
        }
    },

    /**
     * Component mounted lifecycle
     * @return {void}
     * */
    mounted() {
        if (this.initApp()) {
            window.addEventListener('resize', this.onWindowResize)
            this.initContainers()
            this.addNewBg()
        }
    }
}

/**
 * Module export
 */
export default bgPattern

/**
 * @file constants.js
 * @description Constant
 */

export const APP = {
    /**
     * Application name
     * @type {String}
     */
    NAME: 'theatre-du-chatelet'
}

export const EVENTS = {
    /**
     * Window events
     * @type {String}
     */
    WINDOW_RESIZE: 'window-resize',
    WINDOW_RESIZE_IMMEDIATE: 'window-resize-immediate',

    /**
     * Key events
     */
    KEY_UP_ESCAPE: 'key-up-escape',

    /**
     * Image events
     */
    IMAGE_LOAD: 'image-load',
    IMAGE_REFRESH: 'image-refresh',

    /**
     * Artists events
     */
    ARTIST_BEFORE_UPDATE: 'artist-before-update',
    ARTIST_SCROLL_END: 'artist-scroll-end',

    SEARCH_HIDE: 'search-hide',
    AGENDA_HIDE: 'agenda-hide',

    COMPONENT_MOUNTED: 'component-mounted',

    WIDGET_LOADED: 'widget-loaded'
}

export const SWIPER = {
    loop: false,
    speed: 600,
    grabCursor: true,
    navigation: false,
    watchOverflow: true,
    wrapperClass: 'events-wrapper',
    slideClass: 'event-item',
    slidesPerView: 'auto',
    /* Fix iOs out of bound unreachable items */
    setWrapperSize: true,
    threshold: 5,
    touchEventsTarget: 'wrapper',
    /* roundLengths: true, */
    init: false
}

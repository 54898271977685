import * as PIXI from 'pixi.js'
import { TweenMax, Power4 } from 'gsap'

export default class extends PIXI.Container {
    constructor(width, height, color, rotation) {
        super()
        const defaultColor = 0xFFFFFF

        this.r = rotation
        this.container = new PIXI.Container()

        if ((width && height) && (width > 0 && height > 0)) {
            const rectShape = new PIXI.Graphics()

            rectShape.beginFill(color || defaultColor, 0.5)
            rectShape.drawRect(-width / 2, -height / 2, width, height)
            rectShape.endFill()

            this.container.addChild(rectShape)
            this.container.x = width / 2
            this.container.y = height / 2
            this.container.rotation = rotation * (Math.PI / 180)
            this.addChild(this.container)
        }
    }

    animate() {
        return new Promise(
            resolve => {
                const tweenTime = 3 // sec
                const tweenType = Power4.easeOut
                const maxDelay = 0.3

                TweenMax.fromTo(
                    this.container,
                    tweenTime,
                    { rotation: (this.r + 25) * (Math.PI / 180) },
                    {
                        rotation: this.r * (Math.PI / 180),
                        ease: tweenType,
                        delay: Math.random() * maxDelay,
                        onComplete: resolve
                    }
                )
            }
        )
    }
}

/**
 * @file menu.js
 * @description Main menu
 */

import { Power4, TimelineMax, TweenLite } from 'gsap'
import { EVENTS } from '~/config/constants'
import BasicMixin from '~/mixins/basic'

let mainNavigationConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'main-navigation',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        menuActive: {
            type: [ Number, Boolean ],
            default: false
        },
        seasonActive: {
            type: Number,
            default: 1
        },
        visible: {
            type: Boolean,
            default: false
        }
    },

    /**
     * Component data
     * @return {Object}
     */
    data() {
        let data = {
            menu: 0,
            season: 0
        }

        return data
    },

    /**
     * Data watchers
     * @type {Object}
     */
    watch: {
        visible(visible) {
            if (visible) {
                this.menuTween.play()
            } else {
                this.menuTween.reverse()
            }
        }
    },

    /**
     * Component created event handler
     * @return {Void}
     */
    created() {
        this.season = this.seasonActive
        this.menuTween = false

        this.$event.$on(EVENTS.WINDOW_RESIZE, this.updateDefaultMenu)
    },

    /**
     * Component mounted
     * @return {Void}
     */
    mounted() {
        if (this.$el) {
            TweenLite.set(this.$el, { autoAlpha: 1 })
        }

        this.updateDefaultMenu()
        this.registerTween()
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Update default menu
         * @return {Void}
         */
        updateDefaultMenu() {
            if (!this.visible) {
                if (this.$event.screen.width < 768) {
                    this.menu = false
                } else {
                    this.menu = this.menuActive
                }
            }
        },

        /**
         * Register tween
         * @return {Void}
         */
        registerTween() {
            const content = this.$el.querySelector('.main-navigation--main')
            const bottom = this.$el.querySelector('.main-navigation--bottom')

            /**
             * Main menu tween
             */
            this.menuTween = new TimelineMax(
                {
                    paused: true,
                    yoyo: true,
                    onReverseCompleteScope: this,
                    onStartScope: this,
                    onStart() {
                        document.body.classList.add('menu-visible')
                    },
                    onReverseComplete() {
                        document.body.classList.remove('menu-visible')
                        this.updateDefaultMenu()
                    }
                }
            )

            if (this.$el instanceof HTMLElement) {
                this.menuTween
                    .fromTo(
                        this.$el,
                        1,
                        { yPercent: -100 },
                        {
                            yPercent: 0,
                            immediateRender: true,
                            ease: Power4.easeInOut
                        }
                    )
            }

            if (content instanceof HTMLElement) {
                this.menuTween
                    .fromTo(
                        [
                            content,
                            bottom
                        ],
                        1,
                        {
                            autoAlpha: 0
                        },
                        {
                            autoAlpha: 1,
                            ease: Power4.easeOut
                        },
                        0.6
                    )
            }
        },

        /**
         * Set active menu
         * @param {Number} index
         */
        setMenu(index) {
            if (this.menu === index) {
                this.menu = false
            } else {
                this.menu = index
            }
        },

        /**
         * Set active season
         * @param {Number} index
         */
        setSeason(index) {
            this.season = index
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const MainNavigation = Vue.extend(mainNavigationConfiguration)

/**
 * Module export
 */
export default MainNavigation

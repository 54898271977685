/**
 * @file home-filter-carousel.js
 * @description Home filter events
 */

import { EVENTS } from '~/config/constants'
import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'

/**
 * Event carousel configuration
 * @type {Object}
 */
let eventCarouselConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'home-filter-carousel',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        start: {
            type: Number,
            default: 0
        },
        isdefault: {
            type: Boolean,
            default: true
        },
        haveResults: {
            type: Boolean,
            default: false
        },
        isSuggestion: {
            type: Boolean,
            default: false
        },
        noresultwithsuggestions: {
            type: String,
            default: 'Aucun résultat, vous pourriez aimer...'
        },
        noresults: {
            type: String,
            default: 'Aucun résultat'
        },
        events: {
            type: Array,
            default() {
                return []
            }
        }
    },

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperConfig: {
        slidesPerView: 3,
        breakpoints: {
            1279: { slidesPerView: 2 },
            767: { slidesPerView: 1 }
        }
    },

    /**
     * Data watchers
     * @type {Object}
     */
    watch: {
        events() {
            setTimeout(
                () => {
                    this.$event.dispatch(EVENTS.IMAGE_LOAD)

                    if (this.carouselInstance) {
                        this.carouselInstance.update()
                    }
                },
                200
            )
        }
    },

    /**
     * Computed props
     * @type {Object}
     */
    computed: {
        message() {
            return this.events.length ? this.noresultwithsuggestions : this.noresults
        }
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Do action
         * @return {Void}
         */
        doAction(target) {
            if (target) {
                window.location.href = target
            }
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const EventCarousel = Vue.extend(eventCarouselConfiguration)

/**
 * Module export
 */
export default EventCarousel

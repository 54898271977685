/**
 * @file artistes-carousel.js
 * @description Artistes carousel
 */

import { TimelineMax } from 'gsap'
import BasicMixin from '~/mixins/basic'
import Artists from '~/utils/artists'

/**
 * Event dates carousel configuration
 * @type {Object}
 */
let artistesCarouselConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'artistes-filter',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        categories: {
            type: Array,
            default: []
        }
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        /**
         * Selected categories
         * @return {Array}
         */
        selectedCategories() {
            const selectedCategories = _.filter(
                this.categories,
                value => {
                    return _.includes(Artists.selected, value.id)
                }
            )

            // Push filter link
            selectedCategories.push(
                {
                    id: 0,
                    name: 'Filtre'
                }
            )

            return selectedCategories
        },

        /**
         * Visibility
         * @return {Boolean}
         */
        visible() {
            return this.selectedCategories.length > 1
        }
    },

    /**
     * Component created handler
     * @return {Void}
     */
    created() {
        this.tween = false
    },

    /**
     * Component mounted handler
     * @return {Void}
     */
    mounted() {
        this.registerTween()
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Register tween
         * @return {Void}
         */
        registerTween() {
            this.tween = new TimelineMax(
                {
                    paused: true,
                    yoyo: true
                }
            )
        },

        /**
         * Update filter
         * @param  {Number} category
         * @return {Void}
         */
        updateFilter(category) {
            if (!category) {
                Artists.updateSelected(false)
            }

            /*
            if (category) {
                Artists.updateSelected(category);
            } else {
                Artists.updateSelected(false);
            }
            */
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const ArtistesCarousel = Vue.extend(artistesCarouselConfiguration)

/**
 * Module export
 */
export default ArtistesCarousel

import * as PIXI from 'pixi.js'
import * as PATTERN_CONF from './bg-pattern-conf'
import * as PATTERN_CONF_API from './bg-pattern-api-conf'
import BaseRect from './bg-pattern-base-rect'
import BaseCircle from './bg-pattern-base-circle'

export default class extends PIXI.Container {
    /*
    * Conf square :  patternType, squareW, gutter, squareRectSchemas
    * */
    constructor(conf) {
        super()
        this.draw(conf)
    }

    getRandomInt(_min, _max) {
        const min = Math.ceil(_min)
        const max = Math.floor(_max)

        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    /**
     * Draw pattern
     * */
    draw(conf) {
        this.removeChildren()

        if (conf.patternType === PATTERN_CONF_API.TYPE_PATTERN.RECT && conf.squareRectSchemas) {
            for (let param of conf.squareRectSchemas) {
                const gr = new BaseRect(conf.gutter * param.w, conf.gutter * param.h, null, param.r)

                gr.x = conf.gutter * param.x
                gr.y = conf.gutter * param.y

                this.addChild(gr)
            }

            this.alpha = PATTERN_CONF.PATTERN_DEFAULT_ALPHA
        }

        if (conf.patternType === PATTERN_CONF_API.TYPE_PATTERN.CIRCLE && conf.circleRectSchemas) {
            for (let param of conf.circleRectSchemas) {
                const gr = new BaseCircle(param.w)

                gr.x = conf.gutter * param.x
                gr.y = conf.gutter * param.y

                this.addChild(gr)
            }
        }
    }

    /**
     * Anim each item
     * */
    animItems() {
        return new Promise(
            resolve => {
                let animated = 0

                for (const child of this.children) {
                    let draw = child.animate()

                    draw.then(
                        () => {
                            ++animated

                            if (this.children.length === animated) {
                                resolve()
                            }
                        }
                    )
                }
            }
        )
    }
}

/**
 * @file actualites.js
 * @description Actualites
 */

import Masonry from 'masonry-layout'
import BasicMixin from '~/mixins/basic'
import { EVENTS } from '~/config/constants'

/**
 * Component configurations
 * @type {Object}
 */
let actualitesConfiguration = {
    name: 'actualites',

    mixins: [
        BasicMixin
    ],

    /**
     * Component data
     * @return {Object}
     */
    data() {
        const data = { masonry: null }

        return data
    },

    /**
     * Component mounted
     * @return {void}
     */
    mounted() {
        this.refreshTimeout = null
        this.refreshInterval = null

        this.buildMasonry()
        this.addEventsListeners()

        this.$event.$on(EVENTS.WIDGET_LOADED, this.refresh)
    },

    /**
     * Component methods
     */
    methods: {
        /**
         * Build masonry
         */
        buildMasonry() {
            const grid = this.$el.querySelector('.actus-grid')

            if (grid instanceof HTMLElement) {
                this.masonry = new Masonry(
                    grid,
                    {
                        columnWidth: '.actus-sizer',
                        itemSelector: '.actus-item',
                        percentPosition: true,
                        stagger: 0,
                        transitionDuration: 0
                    }
                )

                this.refresh()
            }
        },

        /**
         * Add event listeneres
         */
        addEventsListeners() {
            const base = this
            const frames = this.$el.querySelectorAll('iframe, img')

            frames.forEach(function(frame) {
                frame.addEventListener(
                    'load',
                    () => {
                        base.$event.$emit(EVENTS.WIDGET_LOADED)
                    }
                )
            })
        },

        refresh() {
            if (this.masonry) {
                if (this.refreshInterval) {
                    clearInterval(this.refreshInterval)
                }

                if (this.refreshTimeout) {
                    clearTimeout(this.refreshTimeout)
                }

                this.refreshInterval = setInterval(
                    () => {
                        this.masonry.layout()
                    },
                    20
                )

                this.refreshTimeout = setTimeout(
                    () => {
                        clearInterval(this.refreshInterval)
                    },
                    5000
                )
            }
        }
    }
}

/**
 * Component registration
 * @type {Vue}
 */
const Actualites = Vue.extend(actualitesConfiguration)

/**
 * Module export
 */
export default Actualites

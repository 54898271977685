/**
 * @file main.js
 * @description Main storage
 */

import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Modernizr from 'modernizr'

/**
 * Load vuex plugin
 */
Vue.use(Vuex)

/**
 * Store states
 * @type {Object}
 */
const state = {
    teaser: false,
    alert: true,
    teaserint: {
        fr: false,
        en: false
    },
    alertnext: {
        fr: false,
        en: false
    },
    menu: false,
    search: false,
    agenda: false,
    agendaFilters: false,
    agendaCalendar: [],
    alerts: { fr: [] },
    alertshown: { fr: null },
    guide: {
        vertical: false,
        horizontal: false
    }
}

/**
 * Store mutations
 * @type {Object}
 */
const mutations = {
    /**
     * Mutate guide
     * @param {Object} state
     * @param  {Boolean} payload
     */
    updateGuide(state, payload) {
        _.assign(state.guide, payload)
    },

    /**
     * Mutate state
     * @param {Object} state
     * @param {Object} payload
     */
    updateState(state, payload) {
        _.extend(state, payload)
    },

    /**
     * Mutate toggledAlertState
     * @param {Object} state
     * @param {Object} payload
     */
    addAlertDismissed(state, alert) {
        if (state.alerts[alert.lang]) {
            state.alerts[alert.lang].push(alert.alertId)
        } else {
            state.alerts[alert.lang] = []
            state.alerts[alert.lang].push(alert.alertId)
        }
    },

    /**
     * Mutate toggledAlertState
     * @param {Object} state
     * @param {Object} payload
     */
    addAlertShown(state, alert) {
        state.alertshown[alert.lang] = alert.value
    },

    /**
     * Mutate toggledAlertNextState
     * @param {Object} state
     * @param {Object} payload
     */
    addAlertNext(state, next) {
        state.alertnext[next.lang] = next.value
    },

    /**
     * Mutate toggledAlertCurrentLang
     * @param {Object} state
     * @param {Object} payload
     */
    toggleTeaserInt(state, status) {
        state.teaserint[status.lang] = status.value
    }
}

/**
 * Store actions
 * @type {Object}
 */
const actions = {
    /**
     * Toggle guide
     * @param  {Function} context.commit
     * @param  {Boolean} payload
     * @return {Boolean}
     */
    toggleGuide: ({ commit }, payload) => commit('updateGuide', payload),

    /**
     * Toggle teaser
     * @param  {Function} context.commit
     * @param  {Boolean} payload
     * @return {Boolean}
     */
    toggleTeaser: ({ commit }, payload) => commit('updateState', { teaser: payload }),

    /**
     * Toggle alert
     * @param  {Function} context.commit
     * @param  {Boolean} payload
     * @return {Boolean}
     */
    toggleAlert: ({ commit }, payload) => commit('updateState', { alert: payload }),

    /**
     * Toggle menu
     * @param  {Function} context.commit
     * @param  {Boolean} payload
     * @return {Boolean}
     */
    toggleMenu: ({ commit }, payload) => commit('updateState', { menu: payload }),

    /**
     * Toggle search
     * @param  {Function} context.commit
     * @param  {Boolean} payload
     * @return {Boolean}
     */
    toggleSearch: ({ commit }, payload) => commit('updateState', { search: payload }),

    /**
     * Toggle agenda
     * @param  {Function} context.commit
     * @param  {Boolean} payload
     * @return {Boolean}
     */
    toggleAgenda: ({ commit }, payload) => commit('updateState', { agenda: payload }),

    /**
     * Set agenda filters
     * @param  {Function} context.commit
     * @param  {Boolean} payload
     * @return {Boolean}
     */
    setAgendaFilters: ({ commit }, payload) => commit('updateState', { agendaFilters: payload }),

    /**
     * Set agenda calendar
     * @param  {Function} context.commit
     * @param  {Boolean} payload
     * @return {Boolean}
     */
    setAgendaCalendar: ({ commit }, payload) => commit('updateState', { agendaCalendar: payload })
}

/**
 * Vuex persisted state configuration
 */
const vuexPersistedStateConfiguration = {
    key: 'tdc-storage',
    paths: [ 'guide', 'teaser', 'alert', 'alerts' ]
}

/**
 * Main storage configuration
 */
const mainStorageConfiguration = {
    state,
    mutations,
    actions,
    plugins: []
}

/**
 * Set storage type
 */
if (Modernizr.localstorage) {
    vuexPersistedStateConfiguration.storage = window.localStorage
} else if (Modernizr.sessionstorage) {
    vuexPersistedStateConfiguration.storage = window.sessionStorage
}

/**
 * Storage allowed
 * Create store persisted state
 */
if (Modernizr.localstorage || Modernizr.sessionstorage) {
    mainStorageConfiguration.plugins.push(createPersistedState(vuexPersistedStateConfiguration))
}

/**
 * Vuex storage instance
 * @type {Vuex}
 */
const mainStorage = new Vuex.Store(mainStorageConfiguration)

/**
 * Module export
 */
export default mainStorage

/**
 * @file header.js
 * @description Header
 */

import ScrollMagic from 'scrollmagic'
import Agenda from '~/components/agenda.vue'
import MainNavigation from '~/components/menu'
import MainSearch from '~/components/search.vue'
import { EVENTS } from '~/config/constants'
import BasicMixin from '~/mixins/basic'
import store from '~/store/main'

/**
 * Component configuration
 * @type {Object}
 */
let pageHeaderConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'page-header',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Child components
     * @type {Object}
     */
    components: {
        MainNavigation,
        MainSearch,
        Agenda
    },

    /**
     * Component main storage
     */
    store,

    /**
     * Component data
     * @return {Object}
     */
    data() {
        let data = { background: false }

        return data
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        /**
         * Alert
         * @return {Boolean}
         */
        alert: () => store.state.alert,

        /**
         * VisibleAlert
         * @return {Array}
         */
        visibleAlerts: () => store.state.alerts,

        /**
         * VisibleAlert
         * @return {Array}
         */
        alertshown: () => store.state.alertshown,

        /**
         * Search
         * @return {Boolean}
         */
        search: () => store.state.search,

        /**
         * Agenda
         * @return {Boolean}
         */
        agenda: () => store.state.agenda,

        /**
         * Menu
         * @return {Boolean}
         */
        menu: () => store.state.menu,

        /**
         * Teaser
         * @return {Boolean}
         */
        teaser: () => store.state.teaser
    },

    /**
     * Data watcher
     * @type {Object}
     */
    watch: {
        /**
         * Background
         * @param {Boolean} active
         */
        background(active) {
            if (active) {
                document.body.classList.add('menu-background')
            } else {
                document.body.classList.remove('menu-background')
            }
        }
    },

    /**
     * Component created
     * @return {Void}
     */
    created() {
        this.$event.$on(EVENTS.SEARCH_HIDE, this.toggleSearch)
        this.$event.$on(EVENTS.AGENDA_HIDE, this.toggleAgenda)
        this.$event.$on(EVENTS.KEY_UP_ESCAPE, this.hideLayers)
    },

    /**
     * Mounted event handler
     * @return {Void}
     */
    mounted() {
        this.darkTheme = document.body.classList.contains('theme-dark')

        /**
         * Perform initialisation
         */
        this.initialize()
        this.attachEventsHandlers()
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Initialize
         * @return {Void}
         */
        initialize() {
            this.initializeAlert()
            this.initializeStickyLogo()
            this.initializeAnchors()
            this.initializeScrollInvite()
        },

        /**
         * Initialize sticky logo
         * @return {Void}
         */
        initializeStickyLogo() {
            this.scene = new ScrollMagic.Scene(
                {
                    triggerElement: '.main-header--trigger',
                    triggerHook: 'onLeave'
                }
            )

            this.scene
                // .addIndicators({ name: 'main header' })
                .addTo(this.$event.scrollController)
                .on(
                    'start',
                    () => {
                        this.background = !this.background
                    }
                )
        },

        /**
         * Initialize scroll invite
         * @return {void}
         */
        initializeScrollInvite() {
            const invite = document.querySelector('.main-scroll-invite')

            if (invite instanceof HTMLElement && this.$event.scrollController) {
                const scene = new ScrollMagic.Scene(
                    {
                        triggerElement: '.homepage-content-trigger',
                        triggerHook: 0.7
                    }
                )

                scene
                    // .addIndicators({ name: 'scroll invite' })
                    .setClassToggle(document.body, 'invite-hidden')
                    .addTo(this.$event.scrollController)
            }
        },

        /**
         * Attach events handlers
         * @return {Void}
         */
        attachEventsHandlers() {
            /**
             * Lang menu
             */
            $('.current-lang-btn').on(
                'touchstart',
                function() {
                    $(this).toggleClass('active')
                }
            )

            $('.lang-menu').hover(
                function() {
                    $(this).addClass('active')
                },
                function() {
                    $(this).removeClass('active')
                }
            )
        },

        /**
         * Toggle menu
         * @return {Void}
         */
        toggleMenu() {
            store.dispatch('toggleMenu', !this.menu)
        },

        /**
         * Toggle search
         * @return {Void}
         */
        toggleSearch() {
            store.dispatch('toggleSearch', !this.search)
        },

        /**
         * Toggle agenda
         * @return {Void}
         */
        toggleAgenda() {
            store.dispatch('toggleAgenda', !this.agenda)
        },

        /**
         * Hide layers
         * @return {Void}
         */
        hideLayers() {
            if (this.menu && !this.search && !this.agenda) {
                this.toggleMenu()
            }

            if (this.agenda) {
                this.toggleAgenda()
            }

            if (this.search) {
                this.toggleSearch()
            }
        },

        /**
         * Show alert
         */
        toggleAlert(alertId, lang) {
            store.commit('addAlertDismissed', {
                alertId: alertId,
                lang: lang
            })
            // store.dispatch('toggleAlert', !this.alert);
            document.body.classList.toggle('alert-visible')
        },

        /**
         * showAlert
         * @return {Boolean}
         */
        showAlert(alertId, lang) {
            if (!this.visibleAlerts[lang] || !this.visibleAlerts[lang].includes(alertId)) {
                if (!this.alertshown[lang] || this.alertshown[lang] === null) {
                    store.commit('addAlertShown', {
                        lang: lang,
                        value: alertId
                    })

                    return true
                } else {
                    if (this.alertshown[lang] === alertId) {
                        return true
                    } else {
                        return false
                    }
                }
            }

            return false
        },

        /**
         * Initialize alert
         * @return {Void}
         */
        initializeAlert() {
            if (document.querySelectorAll('.main-alert').length) {
                document.body.classList.toggle('alert-visible')
            }
        },

        /**
         * Main overlay action
         * @return {Void}
         */
        mainOverlayAction() {
            if (this.search) {
                this.toggleSearch()
            }
        },

        initializeAnchors() {
            const isEventSingle = document.body.classList.contains('single-evenements')

            if (isEventSingle) {
                const markers = document.querySelectorAll('.section-trigger')
                const anchorContainer = document.querySelector('.main-header--anchors ul')

                _.each(
                    markers,
                    (marker, index) => {
                        const id = marker.getAttribute('id')
                        const target = marker.dataset.name
                        const item = document.createElement('li')
                        const button = document.createElement('button')

                        const triggerElement = document.getElementById(id).parentNode
                        const scene = new ScrollMagic.Scene(
                            {
                                triggerElement: triggerElement,
                                duration: triggerElement.offsetHeight,
                                triggerHook: 0.1
                            }
                        )

                        const calculateDuration = () => {
                            let duration = triggerElement.offsetHeight
                            const blox = document.querySelector('.header--blocks')

                            switch (id) {
                                case 'presentation':
                                    if (blox instanceof HTMLElement) {
                                        duration += blox.offsetHeight
                                    }
                                    break
                            }

                            scene.duration(duration)
                        }

                        scene
                            // .addIndicators({ name: id })
                            .setClassToggle(item, 'active')
                            .addTo(this.$event.scrollController)

                        this.$event.$on(
                            EVENTS.WINDOW_RESIZE,
                            calculateDuration
                        )

                        this.$event.$on(
                            EVENTS.COMPONENT_MOUNTED,
                            () => {
                                setTimeout(
                                    calculateDuration,
                                    1000
                                )
                            }
                        )

                        if (index === 0) {
                            item.classList.add('active')
                        }

                        button.innerText = target
                        button.addEventListener(
                            'click',
                            () => {
                                this.reachSection(id)
                            }
                        )

                        item.appendChild(button)
                        anchorContainer.appendChild(item)
                    }
                )
            }
        },

        reachSection(name) {
            this.$root.scrollToTarget(name)
        },

        reachContent() {
            this.$root.scrollToTarget('homepage-content-trigger', 1, 40)
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const PageHeader = Vue.extend(pageHeaderConfiguration)

/**
 * Module export
 */
export default PageHeader

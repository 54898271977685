/**
 * @file artistes-list.js
 * @description Artistes list
 */

import ScrollMagic from 'scrollmagic'
import { TweenMax, Power4, Power2 } from 'gsap'
import { EVENTS } from '~/config/constants'
import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'
import Artists from '~/utils/artists'

/**
 * Event dates carousel configuration
 * @type {Object}
 */
let artistesListConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'artistes-list',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        start: {
            type: Number,
            default: 0
        },
        anchors: { type: Array },
        artistsData: { type: Array }
    },

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperElement: '.anchor-carousel',
    swiperConfig: {
        wrapperClass: 'anchor-wrapper',
        slideClass: 'anchor-item',
        slidesPerView: 26,
        freeMode: true,
        breakpoints: {
            1279: { slidesPerView: 15.4 },
            1023: { slidesPerView: 16.8 },
            767: { slidesPerView: 14 },
            639: { slidesPerView: 10.45 },
            479: { slidesPerView: 'auto' }
        }
    },

    /**
     * Component data
     * @return {Object}
     */
    data() {
        const data = {
            activeSlug: [],
            selectedSlugIndex: 0,
            filteredArtistsData: false
        }

        return data
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        /**
         * Selected categories
         * @return {Array}
         */
        selectedCategories: () => Artists.selected
    },

    /**
     * Data watchers
     * @type {Object}
     */
    watch: {
        /**
         * Filtered artists data watcher
         * @return {[type]}
         */
        filteredArtistsData() {
            console.log('👩🏻‍🎨 filtered artists updated')
            /**
             * Set scenes
             */
            TweenMax.to(
                this.$refs.list,
                0.5,
                {
                    autoAlpha: 1,
                    y: 0,
                    ease: Power4.easeInOut,
                    onComplete: this.setScene
                }
            )
        },

        /**
         * Selected categories data watcher
         * @return {Void}
         */
        selectedCategories() {
            console.log('👩🏻‍🎨 selected category updated')

            // Update data
            this.updateData()
        }
    },

    /**
     * Component created event handler
     * @return {Void}
     */
    created() {
        // Init non reactive props
        this.listScene = false

        // Update data
        this.updateData()
    },

    /**
     * Component mounted event handler
     * @return {Void}
     */
    mounted() {
        // Attach event handlers
        this.attachEventsHandlers()
    },

    methods: {
        /**
         * Updating data
         * @return {[type]} [description]
         */
        updateData() {
            let artistsData = this.artistsData

            /**
             * Remap data if selected categories
             */
            if (this.selectedCategories.length) {
                /**
                 * Remove artists not in selected categories
                 */
                artistsData = _.map(
                    this.artistsData,
                    artistData => {
                        // Filter content
                        let artists = _.filter(
                            artistData.list,
                            artist => {
                                let inCategory = _.filter(
                                    artist.category,
                                    category => {
                                        return _.includes(this.selectedCategories, category.id)
                                    }
                                )

                                return inCategory.length
                            }
                        )

                        /**
                         * Mapping data
                         */
                        return {
                            slug: artistData.slug,
                            list: artists
                        }
                    }
                )
            }

            /**
             * Remove empty list
             */
            artistsData = _.filter(
                artistsData,
                artistData => {
                    return artistData.list.length
                }
            )

            /**
             * Fill filtered artists data
             * @type {Array}
             */
            this.filteredArtistsData = artistsData
        },

        /**
         * Set scene
         * @return {Void}
         */
        setScene() {
            const sceneOffset = ($('.artistes-list-trigger').position().top + 70) * -1
            const element = document.querySelector('.artistes-list-elements')
            let anchorUpdateTimeout = false
            let initialAnchor = false

            /**
             * Reset active slug
             */
            this.activeSlug.splice(0, this.activeSlug.length)

            /**
             * Set active slug
             */
            _.each(
                this.filteredArtistsData,
                artistData => {
                    if (artistData.list.length) {
                        this.activeSlug.push(artistData.slug)

                        /**
                         * Set initial anchor
                         */
                        if (!initialAnchor) {
                            initialAnchor = artistData.slug
                        }
                    }
                }
            )

            /**
             * Update anchor initial position
             * Then enable available scenes
             */
            if (initialAnchor) {
                this.updateAnchor(initialAnchor, 0, this.activateScene)
            }

            /**
             * Build list scened
             * @type {ScrollMagic}
             */
            this.listScene = new ScrollMagic.Scene(
                {
                    triggerElement: element,
                    triggerHook: 0,
                    offset: sceneOffset,
                    duration: $(element).height() - 20
                }
            )

            /**
             * List scene events handlers
             */
            this.listScene
                .on(
                    'progress',
                    () => {
                        // Abort previous update
                        if (anchorUpdateTimeout) {
                            clearTimeout(anchorUpdateTimeout)
                        }

                        // Do update
                        anchorUpdateTimeout = setTimeout(this.updateActiveAnchor, 300)
                    }
                )
                .on(
                    'leave',
                    event => {
                        if (!event.progress && initialAnchor) {
                            // Abort previous update
                            if (anchorUpdateTimeout) {
                                clearTimeout(anchorUpdateTimeout)
                            }

                            // Update anchor
                            this.updateAnchor(initialAnchor, 0)
                        }
                    }
                )
        },

        /**
         * Update active anchor
         * @return {Void}
         */
        updateActiveAnchor() {
            const trigger = $('.artistes-list-trigger').position().top

            const anchors = _.takeWhile(
                this.filteredArtistsData,
                artistData => {
                    const element = this.$el.querySelector('#artiste-' + artistData.slug)
                    const top = $(element).offset().top

                    return top - trigger - 50 < 0
                }
            )

            if (anchors.length) {
                this.updateAnchor(_.last(anchors).slug)
            }
        },

        /**
         * Activate scens
         * @return {Void}
         */
        activateScene() {
            console.log('👩🏻‍🎨 %cactivating scene', 'color: green; font-weight: bold;')

            if (this.listScene) {
                this.listScene
                    .addTo(this.$event.appScrollController)
            }
        },

        /**
         * Attach events handlers
         * @return {Void}
         */
        attachEventsHandlers() {
            // Attach event handlers
            this.$event.$on(EVENTS.WINDOW_RESIZE, this.updateComponents)
            Artists.$on(EVENTS.ARTIST_BEFORE_UPDATE, this.destroyScene)
        },

        /**
         * Go to target element
         * @param  {String} target
         * @return {Void}
         */
        goto(target) {
            console.log('👩🏻‍🎨 go to %s', target)

            let element = document.getElementById('artiste-' + target)

            if (element instanceof HTMLElement) {
                let container = document.querySelector('.main-app-wrapper')

                // Disable scene
                this.toggleScene()

                TweenMax.to(
                    container,
                    2,
                    {
                        scrollTo: {
                            y: $(element).offset().top + container.scrollTop,
                            x: 0,
                            offsetY: $('.artistes-list-trigger').position().top,
                            autoKill: false
                        },
                        ease: Power4.easeInOut,
                        onStartScope: this,
                        onCompleteScope: this,
                        onStart() {
                            this.updateAnchor(target)
                        },
                        onComplete() {
                            // Enable scene
                            this.toggleScene(true)
                        }
                    }
                )
            }
        },

        /**
         * Filter category
         * @param  {Number} category
         * @return {Void}
         */
        filter(category) {
            if (!_.includes(this.selectedCategories, category)) {
                console.log('👩🏻‍🎨 filter categories')

                // Reset indicator position
                // Then update selected category
                Artists.updateSelected(category)
            }
        },

        /**
         * Update components
         * @return {Void}
         */
        updateComponents() {
            console.log('👩🏻‍🎨 %cupdate components', 'color: blue; font-weight: bold;')

            /**
             * Update anchor indicator
             */
            TweenMax.set(
                $('.anchor-indicator'),
                {
                    x: $('.anchor-item').eq(this.selectedSlugIndex).position().left,
                    overwrite: true,
                    onComplete: this.updateScene
                }
            )
        },

        /**
         * Update scene
         * @return {Void}
         */
        updateScene() {
            console.log('👩🏻‍🎨 %cupdate scenes', 'color: blue; font-weight: bold;')
            const sceneOffset = ($('.artistes-list-trigger').position().top + 70) * -1
            const element = document.querySelector('.artistes-list-elements')

            if (this.listScene) {
                this.listScene.duration($(element).height())
                this.listScene.offset(sceneOffset)
                this.listScene.refresh()
            }
        },

        /**
         * Toggle scene
         * @param  {Boolean} active
         * @return {Void}
         */
        toggleScene(active = false) {
            console.log('👩🏻‍🎨 %ctoggle scene - %s -', 'color: orange; font-weight: bold;', active)

            this.listScene.enabled(active)
        },

        /**
         * Destroy scenes
         * @return {Void}
         */
        destroyScene() {
            console.log('👩🏻‍🎨 %cdestroy scenes', 'color: red; font-weight: bold;')

            this.listScene
                .remove()
                .destroy()
        },

        /**
         * Update anchor
         * @param  {Number} id
         * @param  {Number} delay
         * @return {Void}
         */
        updateAnchor(id, delay = 0, callback = false) {
            this.selectedSlugIndex = this.anchors.indexOf(id)

            TweenMax.to(
                $('.anchor-indicator'),
                1,
                {
                    x: $('.anchor-item').eq(this.selectedSlugIndex).position().left,
                    ease: Power2.easeInOut,
                    delay: delay,
                    /* overwrite: true, */
                    onStartScope: this,
                    onCompleteScope: this,
                    onStart() {
                        if (this.carouselInstance) {
                            this.carouselInstance.slideTo(this.selectedSlugIndex, 500)
                        }
                    },
                    onComplete() {
                        if (callback) {
                            callback()
                        }
                    }
                }
            )
        },

        /**
         * item in category
         * @param  {Array} categories
         * @return {Boolean}
         */
        inCategory(categories) {
            let inCategory = false

            if (this.selectedCategories.length) {
                _.each(
                    categories,
                    category => {
                        if (_.includes(this.selectedCategories, category.id)) {
                            inCategory = true
                        }
                    }
                )
            } else {
                inCategory = true
            }

            return inCategory
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const ArtistesList = Vue.extend(artistesListConfiguration)

/**
 * Module export
 */
export default ArtistesList

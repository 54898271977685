import * as PIXI from 'pixi.js'
import { TweenMax, Power4 } from 'gsap'
import * as PATTERN_CONF from './bg-pattern-conf'
import BaseSquare from './bg-pattern-base-factory'

export default class extends PIXI.Container {
    constructor(conf, patternType) {
        super()

        this.tweenTime = 3 // sec
        this.tweenType = Power4.easeOut
        this.baseW = 0
        this.nbCol = 0
        this.nbLines = 0
        this.patternType = patternType
        this.containerMc = null
        this.tickerAbort = true
        this.tickerStarted = false
        this.resizeTimeout = false

        this.draw(conf, true)
    }

    /**
     * Draw with conf
     * @param conf {Object} : {width, height, gutterW}
     * @param withAnim {boolean} : anim alpha 0 -> 1 after added
     * */
    draw(conf, withAnim) {
        this.removeChildren()
        this.baseW = Math.hypot(screen.width, screen.height) // hypotenuse

        // Calculate nb squares per line
        const squareW = conf.gutterW * PATTERN_CONF.DEFAULT_SQUARE_SIZE_WIDTH
        const squareH = conf.gutterW * PATTERN_CONF.DEFAULT_SQUARE_SIZE_HEIGHT

        this.nbCol = Math.ceil(this.baseW / squareW)
        this.nbLines = 1
        this.containerMc = new PIXI.Container()

        // Draw
        const nbSchemaRect = PATTERN_CONF.PATTERN_RECT_SCHEMAS.length
        const nbSchemaCirc = PATTERN_CONF.PATTERN_CIRC_SCHEMAS.length
        const startX = -((squareW * this.nbCol) / 2)
        const startY = -((squareH * this.nbLines) / 2)
        const rectIndex = Math.floor(Math.random() * nbSchemaRect)
        const circleIndex = Math.floor(Math.random() * nbSchemaCirc)
        const squareConf = new PATTERN_CONF.SquareConf(
            this.patternType,
            squareW,
            conf.gutterW,
            PATTERN_CONF.PATTERN_RECT_SCHEMAS[rectIndex],
            PATTERN_CONF.PATTERN_CIRC_SCHEMAS[circleIndex]) // constructor(patternType, squareW, gutter, squareRectSchemas) {

        for (let i = 0; i < this.nbLines; i++) {
            for (let j = 0; j < this.nbCol; j++) {
                const sq = new BaseSquare(squareConf)

                sq.x = startX + (squareW * j)
                sq.y = startY

                this.containerMc.addChild(sq)
            }
        }

        this.containerMc.x = conf.width / 2
        this.containerMc.y = conf.height / 2
        this.containerMc.alpha = withAnim ? 0 : 1
        this.addChild(this.containerMc)

        if (withAnim) {
            this.containerMc.y = (conf.height / 2) + 100

            setTimeout(
                () => {
                    let containerReady = 0

                    // Restart ticker
                    this.startTicker()

                    TweenMax.to(
                        this.containerMc,
                        this.tweenTime,
                        {
                            y: conf.height / 2,
                            alpha: 0.17,
                            ease: this.tweenType
                        }
                    )

                    for (const item of this.containerMc.children) {
                        let drawContainer = item.animItems()

                        drawContainer.then(
                            () => {
                                ++containerReady

                                if (this.containerMc.children.length === containerReady) {
                                    this.stopTicker()
                                }
                            }
                        )
                    }
                },
                500
            )
        }
    }

    destroy() {
        // Start ticker
        this.startTicker()

        // Prevent ticker stop
        this.tickerAbort = false

        TweenMax.to(
            this.containerMc,
            2,
            {
                y: this.containerMc.y - 30,
                alpha: 0,
                ease: this.tweenType,
                onComplete: () => {
                    this.tickerAbort = true

                    super.destroy()
                }
            }
        )
    }

    /**
     * Start ticker
     * @return {Void}
     */
    startTicker() {
        //  Clear resize timeout
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout)
        }

        if (!this.tickerStarted) {
            PIXI.ticker.shared.start()
            this.tickerStarted = true
        }
    }

    /**
     * Stop ticker
     * @return {Void}
     */
    stopTicker() {
        if (this.tickerAbort && this.tickerStarted) {
            PIXI.ticker.shared.stop()
            this.tickerStarted = false
        }
    }

    onResize(rectInfo) {
        this.containerMc.x = rectInfo.width / 2
        this.containerMc.y = rectInfo.height / 2

        //  Clear resize timeout
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout)
        }

        this.resizeTimeout = setTimeout(
            () => this.stopTicker(),
            1000
        )
    }
}

/**
 * @file event-highlight.js
 * @description Event carousel
 */

import BasicMixin from '~/mixins/basic'
import CarouselMixin from '~/mixins/carousel'

/**
 * Event highlight configuration
 * @type {Object}
 */
let eventHighlightConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'event-highlight',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [
        BasicMixin,
        CarouselMixin
    ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        start: {
            type: Number,
            default: 0
        }
    },

    /**
     * Swiper configuration
     * @type {Object}
     */
    swiperConfig: {
        slidesPerView: 3,
        breakpoints: {
            1279: { slidesPerView: 2 },
            767: { slidesPerView: 1 }
        }
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Do action
         * @return {Void}
         */
        doAction(target) {
            if (target) {
                window.location.href = target
            }
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const EventHighlight = Vue.extend(eventHighlightConfiguration)

/**
 * Module export
 */
export default EventHighlight

/**
 * @file event-list
 * @description Event list
 */

import { EVENTS } from '~/config/constants'
import BasicMixin from '~/mixins/basic'
import Events from '~/utils/events-list'

/**
 * Event list configuration
 * @type {Object}
 */
let eventListConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'events-list',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component computed props
     * @type {Object}
     */
    computed: {
        categories: () => Events.categories,
        selectedCategories: () => Events.selectedCategories
    },

    /**
     * Component data watchers
     * @type {Object}
     */
    watch: {
        /**
         * Selected categories
         * @param selected
         * @return {Void}
         */
        selectedCategories() {
            // Update events list
            this.updateEventsList()

            // Dispatch image refresh
            this.$event.dispatch(EVENTS.IMAGE_REFRESH)
        }
    },

    /**
     * Component mounted event handler
     * @return {Void}
     */
    mounted() {
        this.buildCategories()
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Do action
         * @return {Void}
         */
        doAction(target) {
            if (target) {
                window.location.href = target
            }
        },

        /**
         * Build categories
         * @return {Void}
         */
        buildCategories() {
            $('.event-item', this.$el).each(
                (index, element) => {
                    _.each(
                        $(element).data('category'),
                        category => {
                            Events.addCategory(category)
                        }
                    )
                }
            )
        },

        /**
         * Update event list
         * @return {Void}
         */
        updateEventsList() {
            $('.events-list', this.$el).each(
                (index, list) => {
                    let visible = 0

                    $('.event-item', list).each(
                        (index, element) => {
                            let selected = !this.selectedCategories.length

                            _.each(
                                $(element).data('category'),
                                category => {
                                    if (_.find(this.selectedCategories, ['name', category])) {
                                        selected = true
                                    }
                                }
                            )

                            if (selected) {
                                $(element).show()
                                ++visible
                            } else {
                                $(element).hide()
                            }
                        }
                    )

                    /**
                     * Toggle parent section visibility
                     */
                    if (visible) {
                        $(list).parents('.section-basic')
                            .removeClass('empty-list')
                            .show()
                    } else {
                        $(list).parents('.section-basic')
                            .addClass('empty-list')
                            .hide()
                    }
                }
            )
        }
    }
}

/**
 * Register component
 * @type {Vue}
 */
const EventList = Vue.extend(eventListConfiguration)

/**
 * Module export
 */
export default EventList
